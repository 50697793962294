import React, { Component } from 'react'
import numeral from 'numeral'
import DiscountBadgeFooter from './DiscountBadgeFooter'
import { connect } from 'react-redux';

class Footer extends Component {
  render() {
    const { data, discount = 0 } = this.props;
    const { scope } = this.props.globalState;
    const currency = this.props.globalState.settings['Base_Currency.Symbol'];
    return (
      <div className="product-card__footer">
        <div>
          <h6 className="product-card__name h-text-grey-cl font-weight-bold h-truncate">{data.Brand_Brand_Name}</h6>
          <p className="product-card__line text-uppercase">{data.Product_Name}</p>
        </div>

        <div>
          <div className="product-card__price mt-2">
            <span className="h-orange-cl">Retail price</span>
            <span className="h-orange-cl product-card__price-line"> | </span>
            <span>{currency} {numeral(data.Retail_Price).format('0,0.00')}</span>
          </div>
          {scope.userRole === 'optician' ? (
            <div className="product-card__price">
              <span className="h-orange-cl">Dealer price</span>
              <span className="product-card__price-line h-orange-cl"> | </span>
              {discount > 0 ?
                <>
                  <strike><span>{currency} {data.Dealer_Price}</span></strike>{'\n'}
                  <span style={{ 'opacity': '0' }} className="product-card__price-hidden-list">
                    <span className="h-orange-cl">Dealer price</span>
                    <span className="product-card__price-line h-orange-cl"> | </span>
                  </span>
                  <span>{currency} {numeral(data.Dealer_Price).format('0,0.00')}</span>
                </>
                : <span>{currency} {numeral(data.Dealer_Price).format('0,0.00')}</span>}
            </div>) : null}
          {data.customization?.Lens_Width ? (
            <div className="product-card__price">
              <span className="h-orange-cl">Lens Width</span>
              <span className="product-card__price-line h-orange-cl"> | </span>
              <span>{data.customization?.Lens_Width} mm</span>
            </div>) : null}
          {data.customization?.Rx ? (
            <div className="product-card__price">
              <span className="h-orange-cl">Rx</span>
              <span className="product-card__price-line h-orange-cl"> | </span>
              <span>{data.customization?.Rx}</span>
            </div>) : null}
        </div>
        <DiscountBadgeFooter data={{ discount }} />
      </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  globalState: { ...state.home }
});

export default connect(mapStateToProps)(Footer);