import autodux from "autodux";

export const { actions, initial, reducer } = autodux({
    slice: "productPanel",
    initial: {
        panelProduct: undefined,
    },
    actions: {
        toggleProductPanel: (state, panelProduct) => ({
            ...state,
            panelProduct,
        }),
    }
});
