import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../Home/Home.duck";
import TotalCountModal from "../TotalCount/TotalCountModal";
import ProductPanel from "../ProductPanel";
import ProductList from "../Home/ProductList";
import Sort from "../Header/Sort";
import { ProductService } from "../../services/Product.Service";

class WishList extends Component {
  state = {
    listContentClass: 'grid-cards'
  }

  componentDidMount() {
    const $ = window.$;
    if ($) {
      $('[data-toggle="popover"]').popover({
        container: "body",
        html: true,
        placement: "bottom",
        sanitize: false
      });

      $('[data-toggle="popover"]').on("click", function (e) {
        $('[data-toggle="popover"]')
          .not(this)
          .popover("hide");
      });

      $(document).on("click", '[data-popover="dismiss"]', function () {
        $('[data-toggle="popover"]').popover("hide");
      });
    }
  }

  sortData = key => e => {
    this.props.actions.toggleSort(key);
  };

  render() {
    const { favouritesList, wishListData, activeSearch, activeSort, activeSortReverse } = this.props.data;
    const productsData = ProductService.searchAndSort(wishListData, activeSearch, activeSort, activeSortReverse);
    return (
      <div>
        <div className="wishlist position-relative">
          <div className="list__header d-flex justify-content-between align-items-center">
            <div className="h-orange-cl list__title-group">
              <span className="list__header-title">Wishlist</span>
              <span className="list__header-text mr-2 ml-2">></span>
              <span className="list__header-text">All Eyewear</span>
            </div>
            {/* Whishlist selection : Commented for the moment 
            <div className="d-flex justify-content-end">
              <div className="list__dropdown">
                <button
                  className="dropdown-toggle text-left h-bg-white-cl h-text-grey-cl position-relative"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Browse Groups
                </button>
                <div
                  className="dropdown-menu h-bg-orange-cl"
                  aria-labelledby="dropdownMenuButton"
                >
                   <button className="dropdown-item" href="#">
                    Aviators (0)
                  </button>
                  <button className="dropdown-item" href="#">
                    Blue Lens Sunglasses (23) <span>Rename</span>
                  </button>
                  <button className="dropdown-item" href="#">
                    Value Eyewear (240)
                  </button>
                  <button className="dropdown-item" href="#">
                    Metal Frames (125)
                  </button>
                  <button className="dropdown-item" href="#">
                    Acetate Fast Movers (234)
                  </button> 
                </div>
              </div>

              <div>
                <span
                  className="list__header-icon"
                  data-toggle="popover"
                  title="Create a new gallery"
                  data-content="
                  <div className='input-group mb-2'>
                    <input placeholder='New gallery name' type='text' className='form-control form-control-sm'>
                  </div>

                  <div className='text-right'>
                    <button type='button' data-popover='dismiss' className='btn btn-danger btn-sm'>Cancel</button>
                    <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Create</button>
                  </div>
                "
                >
                  <i className="fas fa-folder-plus"></i>
                </span>

                <span
                  className="list__header-icon"
                  data-toggle="popover"
                  title="Confirm"
                  data-content="
                  Are you ready to confirm Order Cart 1 with id #105035?
                  <div className='mt-2 text-right'>
                    <button type='button' data-popover='dismiss' className='btn btn-danger btn-sm'>Cancel</button>
                    <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Confirm</button>
                  </div>
                "
                >
                  <i className="fas fa-cart-arrow-down"></i>
                </span>

                <span
                  className="list__header-icon"
                  data-toggle="popover"
                  title="Move to gallery"
                  data-content="
                  <ul className='move-dropdown'>
                    <li className='move-dropdown__item move-dropdown__item--is-selected'><i className='fas fa-folder-plus mr-1'></i> Some gallery name</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 1</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 2</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 3</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 4</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 5</li>
                  </ul>
                  <div className='mt-3 text-right'>
                    <button type='button' data-popover='dismiss' className='btn btn-danger btn-sm'>Cancel</button>
                    <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Confirm</button>
                  </div>
                "
                >
                  <i className="fas fa-file-import"></i>
                </span>

                <span
                  className="list__header-icon"
                  data-toggle="popover"
                  title="Copy to gallery"
                  data-content="
                  <ul className='move-dropdown'>
                    <li className='move-dropdown__item move-dropdown__item--is-selected'><i className='fas fa-folder-plus mr-1'></i> Some gallery name</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 1</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 2</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 3</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 4</li>
                    <li className='move-dropdown__item'><i className='fas fa-folder-plus mr-1'></i> Some gallery name 5</li>
                  </ul>
                  <div className='mt-3 text-right'>
                    <button type='button' data-popover='dismiss' className='btn btn-danger btn-sm'>Cancel</button>
                    <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Confirm</button>
                  </div>
                "
                >
                  <i className="far fa-copy"></i>
                </span>

                <span
                  className="list__header-icon"
                  data-toggle="popover"
                  title="Share"
                  data-content="
                  <div className='d-flex align-items-center'>
                    <i className='fas fa-envelope-open-text h-orange-cl h-text-md mr-3'></i>
                    <p>You can use this link to share this gallery with anyone.</p>
                  </div>
      
                  <div className='input-group my-2'>
                    <input id='copyTarget1' readonly value='https://youandeyeonline.com/' type='text' className='form-control'>
                    <div className='input-group-append h-pointer' onclick='document.getElementById(\'copyTarget1\').select();document.execCommand(\'copy\');'>
                      <span className='input-group-text' id='basic-addon1'><i className='fas fa-copy'></i></span>
              </div>
            </div>
          </div>

          <div className='text-right'>
            <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Close</button>
          </div>
                "
                >
                  <i className="fas fa-share-alt"></i>
                </span>

                <span
                  className="list__header-icon"
                  data-toggle="popover"
                  title="Rename the gallery"
                  data-content="
                  <div className='input-group mb-2'>
                    <input placeholder='Rename' value='some gallery name' type='text' className='form-control form-control-sm'>
                  </div>
      
                  <div className='text-right'>
                    <button type='button' data-popover='dismiss' className='btn btn-danger btn-sm'>Cancel</button>
                    <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Save</button>
                  </div>
                "
                >
                  <i className="fas fa-edit"></i>
                </span>

                <span
                  className="list__header-icon"
                  data-toggle="popover"
                  title="Delete"
                  data-content="
                  Do you want to delete this content?
                  <div className='mt-2 text-right'>
                    <button type='button' data-popover='dismiss' className='btn btn-danger btn-sm'>Cancel</button>
                    <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Confirm</button>
                  </div>
                "
                >
                  <i className="far fa-trash-alt"></i>
                </span>
              </div>
            </div > */}
          </div >
          <div className="list__menu d-flex justify-content-between">
            <button className={`list__menu-icon mr-2 h-pointer ${this.state.listContentClass === 'grid-cards' ? 'active' : ''}`} onClick={() => this.setState({ 'listContentClass': 'grid-cards' })}>
              <i className="fas fa-th"></i>
            </button>
            <button
              className={`list__menu-icon h-pointer mr-2 ${this.state.listContentClass === 'list-cards' ? 'active' : ''}`} onClick={() => this.setState({ 'listContentClass': 'list-cards' })}
            >
              <i className="fas fa-list"></i>
            </button>
            <a
              href="#exampleModalLong"
              className="list__menu-icon h-pointer"
              data-toggle="modal"
            >
              <i className="fas fa-info-circle"></i>
            </a>

            <div className="ml-auto list__dropdown mr-0">
              <Sort sort={activeSort} reverse={activeSortReverse} handleSelect={this.sortData} />
            </div>
          </div>
          <div className="wishlist__content">
            {
              productsData.length > 0 ? (
                <ProductList
                  productsData={productsData}
                  favouritesList={favouritesList}
                  listContentClass={this.state.listContentClass}
                  groupped={false}
                />
              ) : null
            }
          </div>
          {
            productsData.length > 0 ? (
              <div className="product-panel jsProductPanel">
                <ProductPanel
                  productsData={productsData}
                  favouritesList={favouritesList}
                  groupped={false}
                />
              </div>
            ) : null
          }
        </div >
        <div className="backdrop" />
        <TotalCountModal products={productsData} />
      </div >
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: state.home
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(WishList);
