import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as homeActions } from "../Home/Home.duck";
import DataFilter from "./DataFilter";
import PriceSlider from "./PriceSlider";
import BrandLogos from "./BrandLogos";
import FilterSelected from "./FilterSelected";
import { ApiService } from "../../services/Api.Service";
import { ProductService } from "../../services/Product.Service";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

class Filter extends Component {
  state = {
    availableFilters: undefined,
    STORE_BASE_CURRENCY: undefined
  };

  handlePriceFilter = priceType => values => {
    this.props.actions.setPriceFilter({ name: priceType, value: values });
    this.reloading = true;
    sleep(1000).then(() => {
      this.reloading = false;
      ProductService.getProducts(this.props.data, this.props.actions);
    });
  };

  handleToggleFilterItem = filterName => (itemId) => {
    this.props.actions.toggleActiveFilters({ name: filterName, id: itemId });
    this.reloading = true;
    sleep(1000).then(() => {
      this.reloading = false;
      ProductService.getProducts(this.props.data, this.props.actions);
    });
  };

  clearActiveFilters = () => {
    this.props.actions.clearActiveFilters();
    this.reloading = true;
    sleep(500).then(() => {
      this.reloading = false;
      ProductService.getProducts(this.props.data, this.props.actions);
    });
  };

  getApplicableFilters = async () => {
    const availableFilters = await ApiService.getAvailableFilters();
    this.setState({ availableFilters });
  };

  getAPISettings = async () => {
    const apiSettings = await ApiService.getAPISettings();
    const STORE_BASE_CURRENCY = apiSettings['Base_Currency.Symbol'];
    this.setState({ STORE_BASE_CURRENCY });
  };

  filterToggle = (e) => {
    e.stopPropagation();
    const $ = window.$;
    if (e.target.classList.contains("jsFilterValue")) {
      $("body").toggleClass("filter-opened");
      $(".jsFilterCarousel")
        .not(".slick-initialized")
        .slick({
          dots: false,
          infinite: false,
          slidesToShow: 5,
          prevArrow:
            '<i class="ml-1 fas fa-caret-left slick-arrow slick-arrow--small slick-prev h-orange-cl"></i>',
          nextArrow:
            '<i class="mr-1 fas fa-caret-right slick-arrow slick-arrow--small slick-next h-orange-cl"></i>'
        });
    }
  }

  async componentDidMount() {
    await Promise.all([
      this.getApplicableFilters(),
      this.getAPISettings()
    ]);
  }

  render() {
    if (!this.state.availableFilters) {
      return null;
    }

    const { scope, activeFilters, priceFilters } = this.props.data;
    const hasFilters = Object.keys(activeFilters).length > 0 || Object.keys(priceFilters).length > 0;

    const {
      availableFilters,
      STORE_BASE_CURRENCY
    } = this.state;

    const brands = availableFilters.filter(f => f.name === 'Brand').map(f => f.options)[0];
    const ranges = {
      Dealer_Price: {},
      Retail_Price: {},
    };
    ProductService.allProducts.forEach(p => {
      for (let price of Object.keys(ranges)) {
        const range = ranges[price];
        const productPrice = Number(p[price]);
        if (!range.lowest || range.lowest > productPrice) range.lowest = productPrice;
        if (!range.highest || range.highest < productPrice) range.highest = productPrice;
      }
    });

    return (
      <div className={`filter ${hasFilters ? 'active-filter' : ''}`} onClick={this.filterToggle}>
        <span className="filter__value d-flex justify-content-center align-items-center h-text-white-cl">
          <span className="filter__value-text text-uppercase jsFilterValue">
            Filter
          </span>
        </span>
        {availableFilters ? (
          <div className="filter__content p-4">
            <div className="d-flex h-text-base mb-4 filter__selected-wrap">
              <div className="flex-shrink-1 h-flex-1">
                {
                  Object.keys(activeFilters).map(filterName => {
                    return activeFilters[filterName].map(filterValue => {
                      const filter = availableFilters.filter(f => f.name === filterName)[0];
                      let filterLabel = filter?.options?.filter(o => o.id === filterValue)[0]?.name;
                      return <FilterSelected key={`${filterName}${filterValue}`} filterTitle={filter?.title} filterLabel={filterLabel} onRemoveFilter={() => this.handleToggleFilterItem(filterName)(filterValue)} />

                    });
                  })
                }
              </div>
              {
                Object.keys(activeFilters).length > 0 ? (
                  <span
                    className="h-orange-cl ml-auto h-pointer"
                    onClick={this.clearActiveFilters}
                  >
                    Clear all filters
                  </span>
                ) : null
              }
            </div>
            <div>
              <BrandLogos
                brands={brands}
                handleToggleFilterItem={this.handleToggleFilterItem("Brand")}
                selectedItems={activeFilters.Brand ?? []}
              />
            </div>
            <div className="row">
              {
                scope.userRole === 'optician' ?
                  <PriceSlider
                    slider_title="Dealer Price"
                    values={priceFilters.Dealer_Price || [0, ranges.Dealer_Price.highest]}
                    maxAmount={ranges.Dealer_Price.highest}
                    onAmountChange={this.handlePriceFilter("Dealer_Price")}
                    STORE_BASE_CURRENCY={STORE_BASE_CURRENCY}
                  />
                  : null
              }
              <PriceSlider
                slider_title="Retail Price"
                values={priceFilters.Retail_Price || [0, ranges.Retail_Price.highest]}
                maxAmount={ranges.Retail_Price.highest}
                onAmountChange={this.handlePriceFilter("Retail_Price")}
                STORE_BASE_CURRENCY={STORE_BASE_CURRENCY}
              />
            </div>
            <div className="row">
              {availableFilters.map(({ title, options, name }) => {
                const selectedItems = activeFilters[name] ?? [];
                return (
                  <DataFilter
                    key={name}
                    title={title}
                    names={options}
                    handleToggleFilterItem={this.handleToggleFilterItem(name)}
                    selectedItems={selectedItems}
                  />
                );
              })}
            </div>
          </div>
        ) : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: { ...state.home }
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...homeActions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
