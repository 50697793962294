import { ProductService } from "./Product.Service";

const FILTERS = [
    {
        name: 'Brand',
        title: 'Brand',
        report: 'All_Brands'
    },
    {
        name: 'Eyewear_Collection',
        title: 'Collection',
        report: 'All_Eyewear_Collections'
    },
    {
        name: 'Product_Category',
        title: 'Category',
        report: 'All_Product_Categories'
    },
    {
        name: 'Gender',
        title: 'Gender',
        report: 'All_Genders'
    },
    {
        name: 'Frame_Type',
        title: 'Frame Type',
        report: 'All_Frame_Types'
    },
    {
        name: 'Frame_Colour',
        title: 'Frame Colour',
        report: 'All_Frame_Colours'
    },
    {
        name: 'Frame_Shape',
        title: 'Frame Shape',
        report: 'All_Frame_Shapes'
    },
    {
        name: 'Temple_Colour',
        title: 'Temple Colour',
        report: 'All_Temple_Colours'
    },
    {
        name: 'Tips_Colour',
        title: 'Tips Colour',
        report: 'All_Tips_Colours'
    },
    {
        name: 'Frame_Material',
        title: 'Frame Material',
        report: 'All_Frame_Materials'
    },
    {
        name: 'Temple_Material',
        title: 'Temple Material',
        report: 'All_Temple_Materials'
    },
    {
        name: 'Tips_Material',
        title: 'Tips Material',
        report: 'All_Tips_Materials'
    },
    {
        name: 'Lens_Colour',
        title: 'Lens Colour',
        report: 'All_Lens_Colours'
    },
    {
        name: 'Lens_Technology',
        title: 'Lens Technology',
        report: 'All_Lens_Technologies'
    },
    {
        name: 'Addon',
        title: 'Addon',
        report: 'All_Addons'
    },
];

export class ApiService {
    static country = null;

    static async getAPISettings() {
        if (!ApiService.cacheSettings) {
            ApiService.cacheSettings = (async () => {
                const URL = `${process.env.REACT_APP_SERVER_BASE_URL}/creator/youandeye-main-console/report/${ApiService.country}_Store_Settings`;
                const response = await fetch(URL);
                if (!response.ok) throw new Error('Cannot get settings : ' + ApiService.country);
                const json = await response.json();
                return json[0];
            })(); // Store promise in cache
        }
        return ApiService.cacheSettings;
    };

    static async getAvailableFilters() {
        if (!ApiService.cacheFilters) {
            ApiService.cacheFilters = (async () => {
                const settings = await ApiService.getAPISettings();
                const ret = [];

                if (settings.Show_All_Attributes === 'TODO') { // Request all data
                    const promises = FILTERS.map(filterDef => fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/creator/youandeye-main-console/report/${filterDef.report}`));
                    const filterValues = await Promise.all(promises);

                    for (let index in FILTERS) {
                        const filterDef = FILTERS[index];
                        const options = await filterValues[index].json();
                        if (options.length === 0) continue;
                        // On va convertir les ID Name
                        options.forEach(opt => {
                            opt.ID = opt[filterDef.name + 'ID'];
                            opt.Name = opt[filterDef.name + 'Name'];
                        });
                        ret.push(Object.assign({ options }, filterDef));
                    }
                }
                else {
                    // Search in product service
                    await ProductService.loadProducts(0, 1, null, null);
                    for (let index in FILTERS) {
                        const filterDef = FILTERS[index];
                        const allValues = {};
                        for (let p of ProductService.allProducts) { // Calculate all the values
                            const ids = p[filterDef.name + '_ID'];
                            if (!ids) continue;
                            const names = p[filterDef.name + '_' + filterDef.name + '_Name'] || p[filterDef.name + '_' + filterDef.name.substring(filterDef.name.indexOf('_') + 1) + '_Name']; // Sometimes we need to remove the value after _ : Temple_Material.Material_Name instead of Temple_Material.Temple_Material_Name
                            const idSplit = ids.split(',');
                            const namesSplit = names.split(',');
                            for (let i = 0; i < idSplit.length; i++) {
                                const id = idSplit[i];
                                const name = namesSplit[i];
                                allValues[id] = { name };

                                // Special case :
                                if (filterDef.name === 'Brand') allValues[id].Brand_Logo_URL = p.Brand_Brand_Logo_URL;
                            }
                        }
                        const options = Object.keys(allValues).map(key => {
                            return Object.assign({ id: key }, allValues[key]);
                        }).sort((o1, o2) => o1.name?.localeCompare(o2.name));
                        if (options.length === 0) continue;
                        ret.push(Object.assign({ options }, filterDef));
                    }
                }
                return ret;
            })(); // Store promise in cache
        }
        return ApiService.cacheFilters;
    }

    static async getBrands() {
        const availableFilters = await ApiService.getAvailableFilters();
        return availableFilters.filter(f => f.name === 'Brand').map(f => f.options)[0];
    }
}