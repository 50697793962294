import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as homeActions } from "../Home/Home.duck";
import { actions as orderCartActions } from "../OrderCart/OrderCart.duck";
import { actions as productPanelActions } from "../ProductPanel/ProductPanel.duck";
import ProductView from "./ProductView";
import { vendorLoadedPromise } from "../../utils/utils";
import { ProductService } from "../../services/Product.Service";

class ProductPanel extends Component {

  componentDidMount() {
    this.componentDidUpdate(); // Initialize first carousel
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.productPanelInitialized && (prevProps?.globalState?.panelProduct !== this.props.globalState?.panelProduct || prevProps?.globalState?.displaySideBar !== this.props.globalState?.displaySideBar)) {
      window.$(".jsProductCarousel").slick('unslick');
      this.productPanelInitialized = false;
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    let selectedGroup = this.props.globalState.panelProduct;
    if (selectedGroup?.group && this.props.groupped) selectedGroup = ProductService.getGroup(selectedGroup.group); // Case of member selection

    const $ = window.$;

    if (!this.productPanelInitialized || prevProps?.globalState?.panelProduct !== this.props.globalState?.panelProduct || prevProps?.globalState?.displaySideBar !== this.props.globalState?.displaySideBar) {
      if (selectedGroup) {
        const productIndex = this.props.productsData.indexOf(this.props.productsData.filter(p => {
          return p.ID === selectedGroup.ID && (!selectedGroup.customization || JSON.stringify(selectedGroup.customization) === JSON.stringify(p.customization));
        })[0]); // Calculate the index before the real rendering in case of modification
        if ($(".jsProductPanel").length) $("body").addClass("product-panel--opened");
        vendorLoadedPromise.then(() => {
          window.requestAnimationFrame(() => {
            // The product panel has been opened
            $(".jsProductCarousel").slick({
              dots: false,
              infinite: false,
              prevArrow:
                '<i class="fas fa-caret-left slick-arrow slick-prev h-orange-cl"></i>',
              nextArrow:
                '<i class="fas fa-caret-right slick-arrow slick-next h-orange-cl"></i>',
              initialSlide: productIndex
            }).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
              if (nextSlide === this.props.productsData.length - 1 && this.props.lastProductDisplayed) {
                this.props.lastProductDisplayed();
              }
            });
            // easyzoom init
            $(".easyzoom").easyZoom();
            this.productPanelInitialized = true;
          });
        });
      }
      else {
        window.$("body").removeClass(
          "product-panel--opened"
        );
      }
    }
  }

  closePanel() {
    this.props.actions.toggleProductPanel(null);
  }

  render() {
    const { productsData, favouritesList, globalState, actions, groupped } = this.props;

    const $ = window.$;
    if (!productsData.initialized && this.productPanelInitialized) { // Les produits ont changé : on va tout redessiner : il faut enlever le carousel avant
      $(".jsProductCarousel").slick('unslick');
      this.productPanelInitialized = false;
    }
    productsData.initialized = true;

    // Stop propagation on click to not close the parent
    return (
      <div className="product h-text-grey-cl" onClick={e => e.stopPropagation()} >
        <div className="product__carousel jsProductCarousel">
          <ProductView
            productsData={productsData}
            actions={actions}
            globalState={globalState}
            favouritesList={favouritesList}
            groupped={groupped}
          />
        </div>
        <button
          type="button"
          onClick={() => this.closePanel()}
          className="product__btn_close"
          title="Close"
        >×</button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  globalState: { ...state.home, ...state.cart, ...state.productPanel }
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...homeActions, ...orderCartActions, ...productPanelActions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPanel);
