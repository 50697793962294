import { ApiService } from "./Api.Service";

const URL_VARIABLES = {
    'eb-id': 'brandIds',
    'ec-id': 'eyewearCollectionIds',
    'os-id': 'opticianId',
    'os-name': 'opticianName',
    'os-email': 'opticianEmail',
    'os-mobile': 'opticianMobile',
    'oe-id': 'employeeId',
    'bo-id': 'brandOwnerId',
    'bo-name': 'brandOwnerName',
    'bo-email': 'brandOwnerEmail',
    'bo-mobile': 'brandOwnerMobile',
    'seller-order-notification': 'sellerOrderNotification',
    'logo': 'logo',
    'ad-banner': 'banner',
    'redirect-uri': 'redirectUri',
    'secret-key': 'secretKey',
};

export class Scope {
    brandIds = [];
    eyewearCollectionIds = [];
    opticianId;
    opticianName;
    opticianEmail;
    opticianMobile;
    brandOwnerId;
    brandOwnerName;
    brandOwnerEmail;
    brandOwnerMobile;
    employeeId;
    logo;
    banner = [];
    sellerOrderNotification = false; // Send email to optician

    secretKey;
    country = null; // From URL
    redirectUri = null;
    referrer;

    constructor() {
        this.country = ApiService.country;
        this.referrer = document.referrer;
        if (document.referrer && !document.referrer.startsWith(window.location.href.substring(0, window.location.href.indexOf(window.location.pathname)))) sessionStorage.setItem('referrer', document.referrer);
        else this.referrer = sessionStorage.getItem('referrer') || document.referrer;

        this.redirectUri = this.referrer;
    }

    // Calculated fields
    get userRole() {
        return this.employeeId ? 'optician' : 'consumer';
    }

    get token() {
        if (this.employeeId) return this.employeeId;
        return '';
    }

    checkValidityErrors() {
        // Check secretKey
        if (this.secretKey && (!process.env.REACT_APP_SECRET_KEYS || !process.env.REACT_APP_SECRET_KEYS.split(';').some(k => k.trim() === this.secretKey))) {
            const msg = 'Invalid secret key';
            delete this.redirectUri;
            return msg;
        }

        if (!this.secretKey && process.env.REACT_APP_FORCE_REFERRER && (!this.referrer || !this.referrer.startsWith(process.env.REACT_APP_FORCE_REFERRER))) {
            const msg = 'Invalid referrer : ' + this.referrer;
            delete this.redirectUri;
            return msg;
        }
        if ((!this.brandIds?.length && !this.eyewearCollectionIds?.length) || !this.opticianId || !this.opticianEmail || !this.opticianName || !this.opticianMobile || (
            this.userRole === 'optician' && (!this.brandOwnerId || !this.brandOwnerName || !this.brandOwnerEmail || !this.brandOwnerMobile)
        )) {
            const msg = 'Missing variable';
            return msg;
        }
        if (this.eyewearCollectionIds?.length) this.brandIds = []; // If we have a collection, we don't check the brand
        return null;
    }

    toURI(uri) {
        if (uri.indexOf('?') === -1) uri += '?';
        for (let key of Object.keys(URL_VARIABLES)) {
            const attr = URL_VARIABLES[key];
            let value = this[attr];
            if (Array.isArray(value)) {
                if (value.length === 0) value = null;
                else value = value.join(',');
            }
            if (!value) continue;

            if (!uri.endsWith('?')) uri += '&';
            uri += key + '=' + encodeURIComponent(value);
        }
        return uri;
    }
}

export class ScopeService {

    static initializeScope(globalActions) {
        if (ApiService.country) return; // Already initialized

        // Calculate country
        if (process.env.REACT_APP_ZOHO_COUNTRY) ApiService.country = process.env.REACT_APP_ZOHO_COUNTRY;
        else {
            const hostPrefix = window.location.hostname.split('.')[0];
            if (hostPrefix.length === 2) { // It is probably a country code
                ApiService.country = hostPrefix;
                // Let's just download the settings to check if the country code exists
                ApiService.getAPISettings().catch(() => {
                    console.error('invalid domain/country : ' + hostPrefix);
                    // window.location.href = process.env.REACT_APP_MISSING_PARAMETERS_URL;
                });
            }
            // else window.location.href = process.env.REACT_APP_MISSING_PARAMETERS_URL;
        }

        let hasScope = false;
        ScopeService.scope = new Scope();
        let parameters = window.location.search.substring(1).split('&');
        for (let param of parameters) {
            const index = param.indexOf('=');
            if (index > -1) {
                const paramName = param.substring(0, index);
                const paramValue = decodeURIComponent(param.substring(index + 1));

                // Search if this is a scope parameter
                for (let key of Object.keys(URL_VARIABLES)) {
                    if (paramName === key) {
                        hasScope = true;
                        const scopeAttr = URL_VARIABLES[key];
                        if (Array.isArray(ScopeService.scope[scopeAttr])) {  // Multivalue parameters
                            ScopeService.scope[scopeAttr] = paramValue.split(',');
                        }
                        else if (typeof ScopeService.scope[scopeAttr] === 'boolean') ScopeService.scope[scopeAttr] = paramValue.toLowerCase() === 'true';
                        else ScopeService.scope[scopeAttr] = paramValue;
                        break;
                    }
                }
            }
        }
        if (!hasScope) {
            const savedScope = window.sessionStorage.getItem('YAE_SCOPE');
            if (savedScope) {
                hasScope = true;
                Object.assign(ScopeService.scope, JSON.parse(savedScope));
            }
        }
        const scopeError = ScopeService.scope.checkValidityErrors();
        if (!hasScope || scopeError) {
            if (scopeError) {
                console.error(scopeError);
                alert('Sorry, either the optician/brand does not have their collections online or you are not authorised to view this page');
            }
            const redirectUrl = ScopeService.scope.redirectUri || process.env.REACT_APP_MISSING_PARAMETERS_URL;
            if (redirectUrl) {
                window.location.href = redirectUrl;
                return;
            }
        }

        globalActions.initializeScope(ScopeService.scope);
        window.sessionStorage.setItem('YAE_SCOPE', JSON.stringify(ScopeService.scope)); // Keep in session in case of F5
    }

    static getScope() {
        return ScopeService.scope;
    }
}