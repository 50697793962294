import React, { Component } from 'react'


class AddButton extends Component {

  render() {
    const { className, addToCart, removeFromCart, selectGroup, cartCount, multiMembers } = this.props;
    let addToCartSpan = null;

    if (multiMembers) addToCartSpan = <span className="add-button__value" style={{ cursor: 'pointer' }} onClick={e => {
      e.stopPropagation();
      selectGroup();
    }}>{cartCount ? cartCount : 'Customize'}</span>
    else if (cartCount > 0) addToCartSpan = <span className="add-button__value">{cartCount}</span>
    else addToCartSpan = <span className="add-button__value" style={{ cursor: 'pointer' }} onClick={addToCart}>Add</span>

    return (
      <span className={`add-button ${className ?? ''} ${multiMembers ? 'multi-members' : ''} ${cartCount ? 'with-products' : 'empty'}`} data-value={cartCount}>
        <button
          className={`add-button__control add-button__control--minus ${!multiMembers && cartCount > 0 ? '' : 'd-none'}`}
          data-action="decrease"
          onClick={removeFromCart}>
          <div className="add-button__control__sign">-</div>
        </button>
        {addToCartSpan}
        {multiMembers ? null :
          <button
            className="add-button__control"
            data-action="increase"
            onClick={addToCart}>
            <div className="add-button__control__sign">+</div>
          </button>
        }
      </span>

    )
  }
}

export default AddButton;