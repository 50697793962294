import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../Home/Home.duck";

class Search extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const input = document.querySelector('#search-zone input:focus');
    if (input && !input.value) input.blur();
  }

  render() {
    const { value, onChange, actions } = this.props;
    return <div id="search-zone" className={`h-relative icon-input ml-auto mr-3 ${value ? 'search-active' : ''}`}>
      <input type="text" value={value} required onChange={onChange} />
      <i className={`fas fa-search navbar-icon ${value ? 'icon-active' : ''}`} onClick={() => actions.toggleSearchDisplayed()}></i>
    </div>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: { ...state.home },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);