import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProductDetails from "./ProductDetails";
import AddButton from "../ProductCard/AddButton";
import AddFavorite from "../ProductCard/AddFavorite";
import DiscountBadge from "../ProductCard/DiscountBadge";
import ModalIcon from "../../img/9.png";
import { encodeFilters } from "../../utils/utils";
import { CUSTOMIZABLE_FIELDS } from "../../services/Product.Service";

class ProductViewItem extends Component {
  constructor(props) {
    super(props);

    const product = this.findCurrentProduct();
    // In order cart, we want to get the current customization
    const customization = product?.customization ?? {};

    this.state = {
      customization
    };
  }

  // Can be converted to react if we display only one product here, so that we can use a state
  changeColorImages = selectedMember => {
    this.props.actions.toggleProductPanel(selectedMember);
  }

  changeCustomization = (key, value) => {
    this.setState({
      customization: { ...this.state.customization, [key]: value }
    });
  }

  componentDidMount() {
    this.checkCurrentCustomization();
  }

  componentDidUpdate() {
    this.checkCurrentCustomization();
  }

  checkCurrentCustomization() {
    // Check if customization is valid
    // Remove keys
    const product = this.findCurrentProduct();

    let customizationModified = false;
    const newCustomization = { ...this.state.customization };
    for (let k of Object.keys(newCustomization)) {
      if (!product[k].length) {
        delete newCustomization[k]; // This field is not customizable anymore for this colour
        customizationModified = true;
      }
    }
    // Set default value for invalid keys
    for (let k of CUSTOMIZABLE_FIELDS) {
      if (product[k].length && product[k].indexOf(newCustomization[k]) === -1) {
        newCustomization[k] = product[k][0];
        customizationModified = true;
      }
    }
    if (customizationModified) this.setState({ customization: newCustomization });
  }

  /* show more images */
  showMoreImages = (e, trigger) => {
    const $ = window.$;
    let btn = $(e.currentTarget);
    let mainImage = btn.parents(".jsRootProduct").find(".jsMainImage");
    let moreImagesWrapper = btn.parents(".product__footer").find(".jsMoreImages");
    let currentCount = Number(moreImagesWrapper.data().active);

    if (btn.hasClass("active")) {
      switch (trigger) {
        case "increase":
          currentCount++;
          btn.prev().addClass("active");
          if (!moreImagesWrapper.children().eq(currentCount + 1).length) {
            btn.removeClass("active");
          }
          break;
        case "decrease":
          currentCount--;
          btn.next().addClass("active");
          if (currentCount === 0) {
            btn.removeClass("active");
          }
          break;
        default:
          break;
      }

      moreImagesWrapper.data("active", currentCount);
      mainImage
        .attr(
          "src",
          moreImagesWrapper
            .children()
            .eq(currentCount)
            .attr("data-src")
        )
        .parent()
        .attr(
          "href",
          moreImagesWrapper
            .children()
            .eq(currentCount)
            .attr("data-src")
        );
      this.easyZoomReinit();
    }
  }

  easyZoomReinit() {
    const $ = window.$;
    $(".easyzoom").each(function (i, item) {
      $(item)
        .data("easyZoom")
        .teardown();
    });
    setTimeout(function () {
      $(".easyzoom").easyZoom();
    }, 300);
  }

  findCurrentProduct() {
    const { panelProduct } = this.props.globalState;
    return this.props.groupped ? (this.props.group.groupMembers.filter(p => p.ID === panelProduct?.ID)[0] || this.props.group.groupMembers[0]) : this.props.group;
  }

  render() {
    const { group, favouritesList, actions, globalState, groupped } = this.props;
    const { activeFilters, itemsInCart, settings, scope } = globalState;
    const STORE_BASE_CURRENCY = settings['Base_Currency.Symbol'];

    const product = this.findCurrentProduct();

    let cartCount = itemsInCart.filter(item => {
      if (item.productId !== product.ID) return false;
      return Object.keys(this.state.customization).every(k => this.state.customization[k] === item.customization[k]);
    });
    cartCount =
      cartCount.length > 0 ? cartCount.map(({ quantity }) => quantity)[0] : 0;
    const discount = 0; // Compute it

    const isFavourited = favouritesList.some(item => product.ID === item);
    const favouriteClass = isFavourited ? "favorite" : "";

    // const classNames = `product-card h-text-grey-cl font-weight-bold position-relative is--discount added-cart ${isFavourited ? 'favorite' : ''}`
    let productViewUrl = '/productview/' + product.ID;
    productViewUrl = scope.toURI(productViewUrl);
    if (Object.keys(activeFilters).length > 0) productViewUrl += '&filters=' + encodeURIComponent(encodeFilters(activeFilters));

    const {
      Brand_Brand_Name,
      Brand_Brand_Logo_URL,
    } = product;

    return (
      <div
        key={product.ID}
        className={`product__slide d-flex flex-column jsRootProduct ${favouriteClass}`}
      >
        <div className="product__header">

          {Brand_Brand_Logo_URL && (
            <Link to="#" className="product__header-product-logo">
              <img src={Brand_Brand_Logo_URL} alt="icon" width="135" />
            </Link>
          )}

          <div className="product__header-group">

            <div className="product__header-name">
              <span className="font-weight-bold">{Brand_Brand_Name}</span>
              <span className="mx-1">|</span>
              <span>{product.Product_Name}</span>
            </div>

            <div className="product__header-type is--discount position-relative">
              {discount > 0 ? (
                <div className="discount-badge">
                  <DiscountBadge discount={discount} />
                </div>
              ) : null}

              <div>
                <span className="h-orange-cl">Retail Price</span>
                <span className="product__header-line h-orange-cl">|</span>
                <span className="product__header-price">{STORE_BASE_CURRENCY} {product.Retail_Price}</span>
              </div>
              {scope.userRole === 'optician' ?
                <div>
                  <span className="h-orange-cl">Dealer price</span>
                  <span className="product__header-line h-orange-cl">|</span>
                  <span className="product__header-price">{STORE_BASE_CURRENCY} {product.Dealer_Price}                  </span>
                </div>
                : null}
            </div>
          </div>

          <div className="product__header-selects">
            {product.Lens_Width?.length ?
              <div className="product-select">
                <label htmlFor="lens">Lens Size</label>
                <select id="lens" value={this.state.customization.Lens_Width} onChange={event => this.changeCustomization('Lens_Width', event.target.value)}>
                  {product.Lens_Width?.map(size => (
                    <option key={size.toString()} value={size.toString()}>{size}mm</option>
                  ))}
                </select>
              </div> : null}
            {product.Rx?.length ?
              <div className="product-select">
                <label htmlFor="rx">Rx</label>
                <select id="rx" value={this.state.customization.Rx} onChange={event => this.changeCustomization('Rx', event.target.value)}>
                  {product.Rx?.map(val => (
                    <option key={val.toString()} value={val}>{val}</option>
                  ))}
                </select>
              </div> : null}
          </div>

          <div className="product__header-cta">
            <AddButton
              className="add-button--large"
              cartCount={cartCount}
              addToCart={() => actions.addToCart({ product, customization: this.state.customization })}
              removeFromCart={() => actions.removeFromCart({ product, customization: this.state.customization })}
            />
            <AddFavorite
              width="34"
              height="34"
              productId={product.ID}
              favouriteClass={favouriteClass}
              handleClick={() => {
                actions.toggleWishList(product);
                actions.toggleFavouritesList(product.ID);
              }}
            />
            <Link
              className="product__header-external-btn product__header-icon"
              to={productViewUrl}
              target="blank"
            >
              <i className="fas fa-external-link-alt"></i>
            </Link>
            <button className="product__header-backlist product__header-icon" onClick={actions.toggleDisplaySideBar}>
              <i className="fas fa-external-link-square-alt"></i>
            </button>
          </div>
        </div>

        <div className={`product__content text-center position-relative d-flex align-items-center justify-content-center ${globalState.infoOpened ? "is-info-opened" : ""}`}>
          <div className="easyzoom easyzoom--overlay">
            <a href={product.Image_URLs[0]}>
              <img
                src={product.Image_URLs[0]}
                className="jsMainImage"
                alt="glasses"
              />
            </a>
          </div>

          <ProductDetails
            product={product}
          />
        </div>

        <div className="product__footer">

          <div className="product__footer-more-images-container">
            <nav className="product__footer-more-images">
              <div>view more images</div>
              <div className="product__footer-more-images__buttons">
                <button className="product__footer-more-images__btn" type="button" onClick={e => this.showMoreImages(e, 'decrease')}>
                  <i className="fas fa-caret-up"></i>
                </button>
                <button className="product__footer-more-images__btn active" type="button" onClick={e => this.showMoreImages(e, 'increase')}>
                  <i className="fas fa-caret-down"></i>
                </button>
              </div>
            </nav>
          </div>

          <div className="d-none jsMoreImages" data-active="0">
            {product.Image_URLs.map(image => (
              <img key={image} data-src={image} alt="" data-active="" />
            ))}
          </div>

          {groupped ?
            <div className="product__footer-alt-colors">
              {group.groupMembers.map(member => (
                <div key={member.ID} className={'product__footer-wrap' + ((member.ID === product.ID) ? ' color-selected' : '')}>
                  <div className="product__footer-color">
                    <img
                      src={member.Image_URLs_small[0]}
                      alt="glasses"
                      className="product__footer-example" onClick={() => this.changeColorImages(member)}
                    />
                  </div>
                  <p className="product__footer-name"></p>
                </div>
              ))}
            </div>
            : null}

          <div className="product__footer-info">
            <button
              type="button"
              onClick={() => actions.toggleInfoOpened()}
              title="More info"
            >
              <img src={ModalIcon} alt="info" />
            </button>
          </div>

        </div>
      </div>
    );
  }
}

export default ProductViewItem;
