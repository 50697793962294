import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "./Home.duck";

class Banner extends Component {
  render() {
    const { image } = this.props;
    const { activeSearch } = this.props.data;
    return image ? (
      <div className={`ad-banner ${activeSearch ? 'banner-minimized' : ''}`}>
        <img src={image} alt="" />
      </div>)
      : null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: { ...state.home },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);