import React from 'react'

const filters = [
  { label: "Brand Name", field: 'Brand_Brand_Name' },
  {
    label: "Price", field: 'Retail_Price'
  },
  { label: "Product Name", field: 'Product_Name' },
  // { label: "Stock", field: '' },
  // { label: "Discount", field: '' }
];

const Sort = ({ handleSelect, sort, reverse }) => {
  const sortActive = reverse || sort !== filters[0].field;
  return (
    <>
      <button
        data-toggle="dropdown"
        id="dropdownMenuButton"
        aria-haspopup="true"
        aria-expanded="false"
        className={`sort-icon ${sortActive ? 'sort-active' : ''}`}
      >
        <i className="fas fa-sort" />
      </button>
      <div className="dropdown-menu dropdown-menu-right h-bg-orange-cl" aria-labelledby="dropdownMenuButton">
        {filters.map(({ label: filter, field }) => (
          <button key={filter.toString()} className="dropdown-item" onClick={handleSelect(field)}>{filter} <i style={{ visibility: field === sort ? 'visible' : 'hidden' }} className={'fas fa-chevron-' + (reverse ? 'up' : 'down')} /></button>
        ))}
      </div>
    </>
  );
};

export default Sort