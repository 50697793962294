import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as homeActions } from "../Home/Home.duck";
import { actions as orderCartActions } from "../OrderCart/OrderCart.duck";
import { withRouter } from 'react-router-dom';

class RetailerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      contactName: "",
      email: "",
      mobile: "",
      organisationName: '',
      validated: false,
    };
    const scope = props.globalState.scope;
    if (scope.userRole === 'optician') {
      this.state.organisationName = props.globalState.scope.opticianName;
      this.state.mobile = props.globalState.scope.opticianMobile;
      this.state.email = props.globalState.scope.opticianEmail;
    }
  }

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      return;
    }
    this.setState({ validated: true });
    this.props.handleSubmitCart(this.state);
  };

  handleChange = field => event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ [field]: event.currentTarget.value });
  };

  handleFinished() {
    const { scope } = this.props.globalState;
    if (scope.redirectUri) window.location.href = scope.redirectUri;
    else this.props.history.push('/');
  }

  render() {
    const {
      validated,
      address,
      contactName,
      email,
      mobile,
      organisationName
    } = this.state;
    const {
      handleClose,
      orderConfirmed,
      showModal,
      submittingCart
    } = this.props;
    const { scope } = this.props.globalState;

    return (
      <Modal
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Body>
          {
            orderConfirmed ? (
              <>
                <h2>Thank you for your order</h2>
                <br />
                <button
                  className="btn-submit h-bg-orange-cl h-text-white-cl text-uppercase"
                  disabled={submittingCart}
                  onClick={() => this.handleFinished()}
                >
                  OK
            </button>
              </>
            )
              : (
                <Form validated={validated} onSubmit={this.handleSubmit}>
                  {scope.userRole === 'optician' ?
                    <Form.Group controlId="formOrganisationName">
                      <Form.Label>Organisation Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        disabled
                        value={organisationName}
                        onChange={this.handleChange("organisationName")}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter the organisation name.
              </Form.Control.Feedback>
                    </Form.Group> : null
                  }
                  <Form.Group controlId="formContactName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      required
                      type="contact"
                      placeholder="Enter full name"
                      value={contactName}
                      onChange={this.handleChange("contactName")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a full name.
              </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formMobile">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      required
                      type="contact"
                      placeholder="Enter mobile"
                      value={mobile}
                      onChange={this.handleChange("mobile")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a mobile number.
              </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={this.handleChange("email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
              </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formAddress">
                    <Form.Label>Addresss</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address"
                      value={address}
                      onChange={this.handleChange("address")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter an address.
              </Form.Control.Feedback>
                  </Form.Group>
                  <button
                    className="btn-submit h-bg-orange-cl h-text-white-cl text-uppercase"
                    type="submit"
                    disabled={submittingCart}
                  >
                    {submittingCart ? "Sending..." : "Send Order"}
                  </button>
                </Form>
              )}
        </Modal.Body>
      </Modal>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  globalState: { ...state.home, ...state.cart }
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...homeActions, ...orderCartActions }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RetailerInfo));
