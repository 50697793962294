import React, { Component } from "react";
import { isEmpty } from "lodash";

function FilterList({ names, title, selectItem, performSearch, selectedItems }) {
  return (
    <div className="filter__block" data-filtertype={title}>
      <div className="filter__block-title">{title}</div>
      <div className="filter__block-body">
        <input
          type="text"
          className="filter__block-input col-12 mb-2 jsFilterListInput"
          placeholder="Type to filter"
          onChange={event => performSearch(event.target.value)}
        />
        <List names={names} selectItem={selectItem} title={title.replace(' ', '').toLowerCase()} selectedItems={selectedItems} />
      </div>
    </div>
  );
}

function List({ names, selectItem, title, selectedItems }) {
  const List = names.map(({ name, id }) => (
    <li key={id} className="align-items-center">
      <input
        type="checkbox"
        id={`${name}${title}`}
        className="mr-1"
        checked={selectedItems.indexOf(id) > -1}
        onChange={() => selectItem(id, `${name}${title}`)}
      />
      <label style={{ margin: 0 }} htmlFor={`${name}${title}`}>{name}</label>
    </li>
  ));
  return <ul >{List}</ul>;
}

class DataFilter extends Component {
  state = {
    filteredNames: undefined
  };

  performSearch = searchTerm => {
    if (isEmpty(searchTerm)) {
      this.setState({
        filteredNames: this.props.names,
        searchTerm
      });
    } else {
      const searchResults = this.props.names.filter(({ name }) =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      this.setState({ filteredNames: searchResults, searchTerm });
    }
  };

  render() {
    const { handleToggleFilterItem, names, title, selectedItems } = this.props;
    const { filteredNames } = this.state;
    return (
      <div className="col-12 col-sm-6 col-md-4 mb-4">
        <FilterList
          title={title}
          names={filteredNames ? filteredNames : names}
          selectItem={handleToggleFilterItem}
          performSearch={this.performSearch}
          selectedItems={selectedItems}
        />
      </div>
    );
  }
}

export default DataFilter;
