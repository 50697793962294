import React from 'react'
const Thumbnail = ({ urls }) => (
  <div className="d-none jsThumbnail" data-active="0">
    {
      urls.filter((url, index) => index > 0).map(url => (
        <img key={url} data-src={url} alt="" />
      ))
    }
  </div>
)
export default Thumbnail