import React, { Component } from "react";
import TabData from "./TabData";

class ProductDetails extends Component {
  render() {
    const { product } = this.props;
    return (
      <div className="product__info">
        <nav>
          <div className="nav" id="navTab" role="tablist">
            <a
              className="active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              About the product
            </a>
            <a
              className=""
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Specifications
            </a>
            <a
              className=""
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-contact"
              role="tab"
              aria-controls="nav-contact"
              aria-selected="false"
            >
              Product Measurements
            </a>
            <a
              className=""
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-contact1"
              role="tab"
              aria-controls="nav-contact"
              aria-selected="false"
            >
              Rating &amp; Review
            </a>
          </div>
        </nav>
        <TabData
          product={product}
        />
      </div>
    );
  }
}

export default ProductDetails;
