import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "./Home.duck";
import ProductList from "./ProductList";
import Filter from "../Filter/Filter";
import TotalCountModal from "../TotalCount/TotalCountModal";
import Banner from "./Banner";
import ProductPanel from "../ProductPanel";
import { ProductService } from "../../services/Product.Service";
import Sort from "../Header/Sort";
import "./Home.scss";

class Home extends Component {

	filterToggle = (e) => {
		e.stopPropagation();
		const $ = window.$;
		if (document.querySelector('.filter .jsFilterValue')) {
			$("body").toggleClass("filter-opened");
			$(".jsFilterCarousel")
				.not(".slick-initialized")
				.slick({
					dots: false,
					infinite: false,
					slidesToShow: 5,
					prevArrow:
						'<i class="ml-1 fas fa-caret-left slick-arrow slick-arrow--small slick-prev h-orange-cl"></i>',
					nextArrow:
						'<i class="mr-1 fas fa-caret-right slick-arrow slick-arrow--small slick-next h-orange-cl"></i>'
				});
		}
	}

	state = {
		apiInfo: undefined,
		listContentClass: 'grid-cards'
	};

	sortData = key => e => {
		this.props.actions.toggleSort(key);
	};

	handleScroll = () => {
		const scrollHeight = window.$(document).height();
		const scrollPos = window.$(window).height() + window.$(window).scrollTop();
		if (!this.props.data.isFetching && !this.props.data.allDataLoaded && ((scrollHeight - 500) >= scrollPos) / scrollHeight === 0) {
			const offset = this.props.data.productsData.length;
			ProductService.getProducts(this.props.data, this.props.actions, offset);
		}
	};

	handleLastProductDisplayed = () => {
		if (!this.props.data.isFetching && !this.props.data.allDataLoaded) {
			const offset = this.props.data.productsData.length;
			ProductService.getProducts(this.props.data, this.props.actions, offset);
		}
	}

	async componentDidMount() {
		ProductService.getProducts(this.props.data, this.props.actions);
		window.addEventListener("scroll", this.handleScroll);
	}

	componentDidUpdate(prevProps) {
		if (this.props.data.activeSort !== prevProps.data.activeSort || this.props.data.activeSortReverse !== prevProps.data.activeSortReverse || this.props.data.activeSearch !== prevProps.data.activeSearch) {
			// Reload products
			ProductService.getProducts(this.props.data, this.props.actions);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	render() {
		const { favouritesList, productsData, isFetching, scope, activeSort, activeSortReverse, activeFilters, priceFilters } = this.props.data;
		const hasFilters = Object.keys(activeFilters).length > 0 || Object.keys(priceFilters).length > 0;

		return (
			<div>
				<Banner image={scope.banner?.[0]} />
				<div className="home position-relative">
					<div className="list__menu mt-0 mb-3 d-flex justify-content-between">
						<button className={`list__menu-icon mr-2 h-pointer ${this.state.listContentClass === 'grid-cards' ? 'active' : ''}`} onClick={() => this.setState({ 'listContentClass': 'grid-cards' })}>
							<i className="fas fa-th"></i>
						</button>
						<button
							className={`list__menu-icon h-pointer mr-2 ${this.state.listContentClass === 'list-cards' ? 'active' : ''}`} onClick={() => this.setState({ 'listContentClass': 'list-cards' })}
						>
							<i className="fas fa-list"></i>
						</button>
						<a
							href="#exampleModalLong"
							className="list__menu-icon h-pointer"
							data-toggle="modal"
						>
							<i className="fas fa-info-circle" />
						</a>

						<div className="ml-auto list__dropdown mr-0">
							<i id="filter-icon" className={`fas fa-filter ${hasFilters ? 'active-filter' : ''}`} onClick={this.filterToggle} />
							<Sort sort={activeSort} reverse={activeSortReverse} handleSelect={this.sortData} />
						</div>
					</div>
					{productsData.length > 0 ? (
						<ProductList
							productsData={productsData}
							favouritesList={favouritesList}
							listContentClass={this.state.listContentClass}
							groupped={true}
						/>
					) : null}
					{isFetching ? <h4 className="fetching">Loading&hellip;</h4> : null}
					<Filter />
					{productsData.length > 0 ? (
						<div className="product-panel jsProductPanel">
							<ProductPanel
								productsData={productsData}
								favouritesList={favouritesList}
								lastProductDisplayed={this.handleLastProductDisplayed}
								groupped={true}
							/>
						</div>
					) : null}
				</div>
				<div className="backdrop" />
				<TotalCountModal products={ProductService.allProducts} />
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	data: { ...state.home },
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...actions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
