import React, { Component } from 'react'
import ProductCard from '../ProductCard/ProductCard'

class ProductList extends Component {
  render() {
    const { productsData, groupped } = this.props;
    return (
      <div className={`row ${this.props.listContentClass}`}>
        {
          productsData.map((product, index) => {
            let key = product.ID;
            if (product.customization) key += ';' + Object.values(product.customization).join(';');
            return <ProductCard
              key={key}
              data={product}
              groupped={groupped}
            />;
          })
        }
      </div>
    )
  }
}

export default ProductList;