import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as homeActions } from "../Home/Home.duck";
import { actions as orderCartActions } from "../OrderCart/OrderCart.duck";
import ProductList from "../Home/ProductList";
import OrderCartModal from "./OrderCartModal";
import TotalCountModal from "../TotalCount/TotalCountModal";
import { calculateOrderValue } from "../../utils/totals";
import { ApiService } from "../../services/Api.Service";
import { ProductService } from "../../services/Product.Service";
import ProductPanel from "../ProductPanel";

import './order-cart.scss';
import Sort from "../Header/Sort";

class OrderCart extends Component {
  state = {
    brands: [],
    carts: [],
    cart: undefined,
    STORE_BASE_CURRENCY: undefined,
    submittingCart: false,
    listContentClass: 'grid-cards'
  };

  sortData = key => e => {
    this.props.actions.toggleSort(key);
  };

  setCart = brandId => {
    const cart = this.processCart(brandId);
    this.setState({ cart });
  };

  processCart = (brandId, brandCart, index) => {
    const { brands } = this.state;
    const brandName = brands.filter(bid => bid.id === brandId)[0]?.name;
    return {
      brandId,
      brandName,
      count: brandCart
        .map(c => c.quantity)
        .reduce((prev, curr) => prev + curr, 0),
      index,
      products: brandCart.map(c => c.product),
      details: brandCart.map(c => ({ pid: c.product.ID, quantity: c.quantity }))
    };
  };

  handleDelete = brandId => {
    console.log("Deleteing brandId ", brandId);
  };

  handleSelectCart = brandId => {
    this.setCart(brandId);
  };

  handleSubmitCart = cart => retailerInfo => {
    const { scope } = this.props.globalState;
    this.setState({ submittingCart: true });
    const orderData = {
      products: cart.cartProducts,
      retailerInfo,
      scope,
    };
    this.submitOrder(orderData)
      .then(() => {
        this.setState({
          orderConfirmed: true,
          submittingCart: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ submittingCart: false });
      });
  };

  submitOrder = async orderData => {
    const { scope } = this.props.globalState;
    const URL = `${process.env.REACT_APP_SERVER_BASE_URL}/cart?token=${scope.token}`;
    const response = await fetch(URL, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(orderData)
    });
    return await response.json();
  };

  async componentDidMount() {
    const brands = await ApiService.getBrands();
    const apiSettings = await ApiService.getAPISettings();
    const STORE_BASE_CURRENCY = apiSettings['Base_Currency.Symbol'];
    this.setState({ brands, STORE_BASE_CURRENCY });

    const $ = window.$;
    if ($) {
      $('[data-toggle="popover"]').popover({
        container: "body",
        html: true,
        placement: "bottom",
        sanitize: false
      });

      $('[data-toggle="popover"]').on("click", function (e) {
        $('[data-toggle="popover"]')
          .not(this)
          .popover("hide");
      });

      $(document).on("click", '[data-popover="dismiss"]', function () {
        $('[data-toggle="popover"]').popover("hide");
      });
    }
  }

  render() {
    const { STORE_BASE_CURRENCY, submittingCart, orderConfirmed } = this.state;
    const {
      favouritesList,
      itemsInCart,
      scope,
      activeSearch, activeSort, activeSortReverse
    } = this.props.globalState;
    let carts = [{
      index: 0,
      brandName: undefined,
      cartProducts: itemsInCart.map(item => {
        return Object.assign({}, item, ProductService.getProduct(item.productId));
      }),
    }];
    let cart = carts[0];
    cart.cartProducts = ProductService.searchAndSort(cart.cartProducts, activeSearch, activeSort, activeSortReverse);
    cart.total = calculateOrderValue(scope.userRole, cart.cartProducts);
    cart.flatProducts = [];
    for (let product of cart.cartProducts) {
      for (let i = 0; i < product.quantity; i++) {
        cart.flatProducts.push(product);
      }
    }

    /*
    let carts = Object.keys(brandsCart);
    if (carts.length > 0) {
      carts = carts
        .map((brandId, index) =>
          this.processCart(brandId, brandsCart[brandId], index)
        )
        .map(cart => {
          const cartProducts = cart.products
            .map(prd => ({
              ...prd,
              quantity: cart.details.filter(({ pid }) => pid === prd.ID)[0]
                .quantity
            }));
          const dataForAPI = cart.products
            .map(prd => ({
              productId: prd.ID,
              quantity: cart.details.filter(({ pid }) => pid === prd.ID)[0]
                .quantity
            }));
          const total = calculateOrderValue(cartProducts);
          return {
            ...cart,
            cartProducts,
            dataForAPI,
            total
          };
        });
      cart = carts[0];
    } else {
      return <></>;
    }
    */
    return (
      <div>
        {carts.length > 0 ? (
          <>
            <section className="order-cart position-relative">
              <div className="list__header d-flex justify-content-between align-items-center">
                <div className="h-orange-cl list__title-group">
                  <span className="list__header-title">
                    Order Cart
                  </span>
                  <span className="list__header-text mr-2 ml-2">></span>
                  <span className="list__header-text">{cart.brandName}</span>
                </div>
                {/* Cart selection Commented for the moment
                <div className="d-flex justify-content-end">
                  <CartSelector
                    carts={carts}
                    handleDelete={this.handleDelete}
                  />
                  {cart ? (
                    <div>
                      <span
                        className="list__header-icon"
                        data-toggle="popover"
                        title="Confirm"
                        data-content={`
                    Are you ready to confirm Order Cart ${cart.index} - ${cart.brandName}?
                    <div className='mt-2 text-right'>
                      <button type='button' data-popover='dismiss' className='btn btn-danger btn-sm'>Cancel</button>
                      <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Confirm</button>
                    </div>`}
                      >
                        <i className="fas fa-check-circle"></i>
                      </span>

                      <span
                        className="list__header-icon"
                        data-toggle="popover"
                        title="Share"
                        data-content={`
                    <div className='d-flex align-items-center'>
                      <i className='fas fa-envelope-open-text h-orange-cl h-text-md mr-3'></i>
                      <p>You can use this link to share this gallery with anyone.</p>
                    </div>
                    <div className='input-group my-2'>
                      <input id='copyTarget1' readonly value='https://youandeyeonline.com/' type='text' className='form-control'>
                      <div className='input-group-append h-pointer' onclick='document.getElementById(\'copyTarget1\').select();document.execCommand(\'copy\');'>
                        <span className='input-group-text' id='basic-addon1'><i className='fas fa-copy'></i></span>
                      </div>
                    </div>
                    <div className='text-right'>
                      <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Close</button>
                    </div>`}
                      >
                        <i className="fas fa-share-alt"></i>
                      </span>

                      <span
                        className="list__header-icon"
                        data-toggle="popover"
                        title="Confirm"
                        data-content="
                    Do you want to delete this content?
                    <div className='mt-2 text-right'>
                      <button type='button' data-popover='dismiss' className='btn btn-danger btn-sm'>Cancel</button>
                      <button type='button' data-popover='dismiss' className='btn btn-brand btn-sm'>Confirm</button>
                    </div>
                  "
                      >
                        <i className="far fa-trash-alt"></i>
                      </span>
                    </div>
                  ) : null}
                  </div> Comment end*/}
              </div>

              <div className="list__menu d-flex justify-content-between">
                <button className={`list__menu-icon mr-2 h-pointer ${this.state.listContentClass === 'grid-cards' ? 'active' : ''}`} onClick={() => this.setState({ 'listContentClass': 'grid-cards' })}>
                  <i className="fas fa-th"></i>
                </button>
                <button
                  className={`list__menu-icon h-pointer mr-2 ${this.state.listContentClass === 'list-cards' ? 'active' : ''}`} onClick={() => this.setState({ 'listContentClass': 'list-cards' })}
                >
                  <i className="fas fa-list"></i>
                </button>
                <a
                  href="#exampleModalLong"
                  className="list__menu-icon h-pointer"
                  data-toggle="modal"
                >
                  <i className="fas fa-info-circle"></i>
                </a>

                <div className="ml-auto list__dropdown mr-0">
                  <Sort sort={activeSort} reverse={activeSortReverse} handleSelect={this.sortData} />
                </div>
              </div>

              <div className="order-cart__content">
                {cart ? (
                  <ProductList
                    productsData={cart.cartProducts}
                    favouritesList={favouritesList}
                    listContentClass={this.state.listContentClass}
                    groupped={false}
                  />
                ) : null}
              </div>
              {
                cart ? (
                  <div className="product-panel jsProductPanel">
                    <ProductPanel
                      productsData={cart.cartProducts}
                      favouritesList={favouritesList}
                      groupped={false}
                    />
                  </div>
                ) : null
              }

              {cart ? (
                <OrderCartModal
                  cart={cart}
                  orderConfirmed={orderConfirmed}
                  submittingCart={submittingCart}
                  handleSubmitCart={this.handleSubmitCart(cart)}
                  STORE_BASE_CURRENCY={STORE_BASE_CURRENCY}
                />
              ) : null}

            </section>
            <TotalCountModal products={cart.flatProducts} />
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  globalState: { ...state.home, ...state.cart }
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...homeActions, ...orderCartActions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCart);
