import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as homeActions } from "../Home/Home.duck";
import { actions as orderCartActions } from "../OrderCart/OrderCart.duck";
import { actions as wishlistActions } from "../WishList/Wishlist.duck";
import { actions as productPanelActions } from "../ProductPanel/ProductPanel.duck";
import Footer from "./Footer";
import AddButton from "./AddButton";
import AddFavorite from "./AddFavorite";
import ProductImage from "./ProductImage";
import Thumbnail from "./Thumbnail";

class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.timeouts = [];
  }

  toggleProductPanel(e, product) {
    this.props.actions.toggleProductPanel(product);
    if (e) e.stopPropagation();
  };

  thumnailAnimation = (e) => {
    let $ = window.$;
    const doAnimation = (_that) => {
      var $thumbnails = $(_that).find(".jsThumbnail");
      var $target = $(_that).find(".jsThumnailTargetImage");

      $thumbnails.children().each((i, item) => {
        var timer = setTimeout(() => {
          $target
            .attr("src", $(item).data().src)
            .stop()
            .fadeIn(300);

          // loop functionality
          if (i === $thumbnails.children().length - 1) {
            var loopTimer = setTimeout(() => {
              doAnimation(_that);
            }, 900);
            this.timeouts.push(loopTimer);
          }
        }, i * 900);
        this.timeouts.push(timer);
      });
    }
    doAnimation(e.currentTarget);
  };

  thumnailAnimationLeave = (e) => {
    let $ = window.$;
    var $target = $(e.currentTarget).find(".jsThumnailTargetImage");

    this.timeouts.forEach((timer, i) => {
      clearTimeout(timer);
    });

    $target
      .stop()
      .hide()
      .attr("src", "");
  }

  render() { // groupped if we regroup products or not
    const { data, actions, groupped, globalState } = this.props;
    const { itemsInCart, favouritesList } = globalState;

    const multiMembers = groupped && data.groupMembers.length > 1;
    let favouriteClass = '';
    let cartCount = 0;

    const showCustomization = data.customization; // In case we are in the cart
    let favouritesMembers = [];
    if (groupped) {
      favouritesMembers = data.groupMembers.filter(member => favouritesList.some(item => member.ID === item));
      if (favouritesMembers.length === data.groupMembers.length) favouriteClass = 'favorite';
      else if (favouritesMembers.length) favouriteClass = 'partial';
      else if (multiMembers) favouriteClass = 'group';
      cartCount = itemsInCart.filter(c => data.groupMembers.some(member => member.ID === c.productId)).map(c => c.quantity).reduce((a, b) => a + b, 0);
    }
    else {
      if (favouritesList.some(item => data.ID === item)) favouriteClass = 'favorite';
      cartCount = itemsInCart.filter(c => {
        if (c.productId !== data.ID) return false;
        if (!showCustomization) return true;
        // Check if we are on the same customization
        return Object.keys(data.customization).every(k => data.customization[k] === c.customization[k]);
      }).map(c => c.quantity).reduce((a, b) => a + b, 0);
    }

    const classNames = `product-card h-text-grey-cl font-weight-bold position-relative is--discount added-cart ${favouriteClass}`;
    return (
      <div className="col-lg-3 col-md-4 col-6 product-card--wrapper">
        <div className={classNames}>
          <div className="product-card__header d-flex justify-content-between align-items-center">
            <AddButton
              cartCount={cartCount}
              addToCart={() => actions.addToCart({ product: data, customization: data.customization })}
              removeFromCart={() => actions.removeFromCart({ product: data, customization: data.customization })}
              selectGroup={() => this.toggleProductPanel(null, data)}
              multiMembers={multiMembers || (!showCustomization && data.customizable)}
            />
            <div className="d-flex">
              <AddFavorite
                className="d-flex h-pointer h-no-bg"
                height="20"
                width="20"
                productId={data.ID}
                favouriteClass={favouriteClass}
                handleClick={() => {
                  if (multiMembers && favouritesMembers.length) { // Some items are selected : remove all of them
                    favouritesMembers.forEach(member => {
                      actions.toggleWishList(member)
                      actions.toggleFavouritesList(member.ID);
                    });
                  }
                  else { // Toggle single item
                    actions.toggleWishList(data)
                    actions.toggleFavouritesList(data.ID);
                  }
                }}
              />
              {/*
              <button
                onClick={console.log}
                style={{ padding: 0 }}
                className="mr-2"
              >
                <i
                  className="far fa-clone copy-icon copy-icon--grey copy-icon--little"
                  title="Add to compare"
                  aria-hidden="true"
                />
              </button>
              <button
                onClick={() => ({})}
                style={{ padding: 0 }}
              >
                <i
                  className="fas fa-list list-icon list-icon--little"
                  title="Add to wishlist"
                  aria-hidden="true"
                ></i>
              </button>
			  */}
            </div>
          </div>
          <div className="product-card__body text-center h-relative mt-4 jsThumbnailWrapper" onClick={e => this.toggleProductPanel(e, data)} onMouseEnter={this.thumnailAnimation} onMouseLeave={this.thumnailAnimationLeave}>
            <ProductImage url={data.Image_URLs_small[0]} />
            <img
              src=""
              alt="glasses"
              className="product-card__preview-img jsThumnailTargetImage"
            />
            <Thumbnail key={data.ID} urls={data.Image_URLs_small} />
          </div>
          <Footer data={data} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  globalState: { ...state.home, ...state.cart, ...state.productPanel }
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...homeActions,
      ...orderCartActions,
      ...productPanelActions,
      addToWishlist: wishlistActions.addToWishlist,
      removeFromWishlist: wishlistActions.removeFromWishlist
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
