import React, { Component } from "react";

class BrandLogos extends Component {

  render() {
    const { brands, handleToggleFilterItem: selectItem, selectedItems } = this.props;
    return (
      <div
        className="filter__carousel jsFilterCarousel mb-4"
        data-filtertype="brand"
      >
        {brands
          ? brands.map(brand => (
            <div className="filter__carousel-slide" key={brand.id}>
              <img
                src={brand.Brand_Logo_URL}
                style={{ maxWidth: "80%" }}
                alt=""
                onClick={() => selectItem(brand.id, brand.name)}
              />
              <div className="mt-1">
                <input
                  type="checkbox"
                  checked={selectedItems.indexOf(brand.id) > -1}
                  className="mr-1"
                  onChange={() => selectItem(brand.id, brand.name)}
                />
              </div>
            </div>
          ))
          : null}
      </div>
    );
  }
}
export default BrandLogos;
