import autodux from 'autodux'

export const { actions, initial, reducer } = autodux({
  slice: 'navbar',
  initial: {
    cartCount: 0,
    compareCount: 0,
    favouriteCount: 0,
    userNotificationCount: 0,
  },
  actions: {
    incrementCartCount: (state) => ({ ...state, cartCount: state.cartCount + 1 }),
    incrementCompareCount: (state) => ({ ...state, compareCount: state.compareCount + 1 }),
    incrementFavouriteCount: (state) => ({ ...state, favouriteCount: state.favouriteCount + 1 }),
    incrementUserNotificationCount: (state) => ({ ...state, userNotificationCount: state.userNotificationCount + 1 })
  }
})