import React, { Component } from "react";
import ProductViewItem from "./ProductViewItem";

class ProductView extends Component {
  render() {
    const { productsData, favouritesList, actions, globalState, groupped } = this.props;

    return productsData.map(group => {
      let key = group.ID;
      if (group.customization) key += ';' + Object.values(group.customization).join(';');

      return <ProductViewItem
        key={key}
        group={group}
        favouritesList={favouritesList}
        actions={actions}
        globalState={globalState}
        groupped={groupped}
      />
    });
  }
}

export default ProductView;
