import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actions as navBarActions } from './Navbar.duck'
import { actions as homeActions } from '../Home/Home.duck'
import Favorite from './Favorite'
import Cart from './Cart'
import User from './User'
import Search from './Search'
import logo from '../../img/ye-placeholder-logo.jpg';
import './navbar.scss';

class Navbar extends Component {

  performSearch = (event) => {
    const searchTerm = event.target.value;
    this.props.actions.toggleSearch(searchTerm);
  };

  render() {
    const { favouritesList, userNotificationCount, itemsInCart, scope, activeSearch, pathname } = this.props.data
    const favouriteCount = favouritesList.length
    const cartCount = itemsInCart.map(({ quantity }) => quantity).reduce((p, c) => p + c, 0);
    return (
      <div>
        <header className="l-header d-flex justify-content-between align-items-center">
          <div className="l-header__logo d-flex justify-content-between align-items-center">
            <Link to="/">
              <img src={scope.logo ?? logo} alt="logo" className="l-header__logo-img" />
            </Link>
          </div>
          <div className="l-header__search d-flex justify-content-between align-items-center">
            <Search value={activeSearch} onChange={this.performSearch} />
            <Favorite count={favouriteCount} active={pathname === '/wishlist'} />
            <Cart count={cartCount} active={pathname === '/ordercart'} />
            <User count={userNotificationCount} active={pathname === '/'} />
          </div>
        </header>

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: { ...state.home, ...state.cart },
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...homeActions, ...navBarActions }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);