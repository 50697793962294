import autodux from "autodux";
import { CUSTOMIZABLE_FIELDS } from "../../services/Product.Service";

const findItemInCart = (itemsInCart, item, customization) => {
  return itemsInCart.filter(cart => {
    if (item !== cart.productId) return false;
    if (!customization) return true;
    if (!cart.customization) return false; // Should not happen
    return Object.keys(customization).every(k => customization[k] === cart.customization[k]);
  })[0];
};

const findDefaultCustomization = product => { // If the product has only one LensSize, it is not customizable, but the customization should have the only value
  const customization = {}
  for (let key of CUSTOMIZABLE_FIELDS) {
    if (product[key] && product[key].length === 1) customization[key] = product[key][0];
  }
  return customization;
}

export const { actions, initial, reducer } = autodux({
  slice: "ordercart",
  initial: {
    isFetching: false,
    itemsInCart: [],
  },
  actions: {
    addToCart: (state, input) => {
      const { itemsInCart } = state;
      let { product, customization } = input;
      if (!customization) customization = findDefaultCustomization(product);
      const item = product.ID;

      const newCartState = itemsInCart.map(item => { return { ...item }; }); // Clone
      const existingItem = findItemInCart(newCartState, item, customization);

      if (existingItem) existingItem.quantity++;
      else newCartState.push({ productId: item, quantity: 1, customization });

      return {
        ...state,
        itemsInCart: newCartState
      };
    },
    removeFromCart: (state, input) => {
      const { itemsInCart } = state;
      let { product, customization } = input;
      if (!customization) customization = findDefaultCustomization(product);
      const item = product.ID;

      const newCartState = itemsInCart.map(item => { return { ...item }; }); // Clone
      const existingItem = findItemInCart(newCartState, item, customization);

      if (existingItem) {
        existingItem.quantity--;
        if (!existingItem.quantity) newCartState.splice(newCartState.indexOf(existingItem), 1);
      }
      return {
        ...state,
        itemsInCart: newCartState
      };
    },
  }
});
