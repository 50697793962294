// Discount code is first applied to all products that are in the Select_Products array (or Select_Product.ID)
// If there are 2 discounts for one product then you need to remove the lower discount.

export const calculateCharge = (userRole, { Retail_Price, Dealer_Price, ProductDiscount }) => {
  // **Maximum Discount Amount**
  // The price is 100 units(in Base Currency of Distributor and NOT Store_Base_Currency)
  // Discount is 70 % ie 70 units
  // IF Maximum Discount is 20 units
  // Maximum Discount will apply.
  // IF Maximum Discount is 80 units
  // Discount(70 Units) will apply
  const isOptician = userRole === 'optician';
  const applicablePrice = isOptician ? Dealer_Price : Retail_Price
  if (applicablePrice && ProductDiscount && isOptician) {
    const { Discount_Percentage, Maximum_Discount_Amount } = ProductDiscount
    const applicableDiscount = Discount_Percentage > Maximum_Discount_Amount ? Maximum_Discount_Amount / 100 : Discount_Percentage / 100
    const discountAmount = applicableDiscount * applicablePrice
    const charge = applicablePrice - discountAmount
    return {
      applicablePrice,
      discountAmount,
      charge
    }
  } else {
    return {
      applicablePrice,
      discountAmount: 0,
      charge: applicablePrice
    }
  }
}

export const calculateOrderValue = (userRole, products) =>
  products
    .map(({ Retail_Price, Dealer_Price, ProductDiscount, quantity }) => {
      const { charge: price } = calculateCharge(userRole, { Retail_Price, Dealer_Price, ProductDiscount })
      return price * quantity
    })
    .reduce((acc, val) => acc + val, 0)