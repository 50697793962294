import { ApiService } from "./Api.Service";
import { ScopeService } from "./Scope.Service";

export const CUSTOMIZABLE_FIELDS = ['Lens_Width', 'Rx'];
const SEARCHABLE_FIELDS = ['Product_Name', 'Brand_Brand_Name'];

const sortFunction = (key, reverse) => (a, b) => {
    if (key === 'Retail_Price') {
        const ret = Number(a[key]) - Number(b[key]);
        return reverse ? -ret : ret;
    }
    const itemA = a[key].toLowerCase();
    const itemB = b[key].toLowerCase();
    if (itemA < itemB) {
        return reverse ? 1 : -1;
    }
    if (itemA > itemB) {
        return reverse ? -1 : 1;
    }
    return 0;
};

const search = (products, searchTerm) => {
    if (!searchTerm) return products;
    const lowerSearch = searchTerm.toLowerCase();
    return products.filter(p => {
        for (let k of SEARCHABLE_FIELDS) {
            const value = p[k];
            if (value && value.toLowerCase().includes(lowerSearch)) return true;
        }
        return false;
    });
}

export class ProductService {
    static allProducts = undefined;
    static products = undefined;

    static async loadProducts(offset, limit, sort, sortReverse, filters, priceFilters, searchTerm) {
        if (!ProductService.loading) {
            ProductService.loading = (async () => {
                const country = ApiService.country;
                const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/creator/youandeye-main-console/report/${country}_All_Eyewear_Products?token=${ScopeService.scope.token}`);
                let data = await response.json();

                const settings = await ApiService.getAPISettings();
                const scope = ScopeService.getScope();

                ProductService.allProducts = data.map(product => {
                    const ret = {};
                    for (let key of Object.keys(product)) {
                        // I replace . by _ that can be used in jquery
                        let newKey = key.replace(/\./g, '_');
                        if (newKey.startsWith(settings.Short_Code + '_')) newKey = newKey.substring(3);
                        ret[newKey] = product[key];
                    }
                    // Image from primary
                    ret.Image_URLs = ret.Primary_Image_URL ? [ret.Primary_Image_URL] : [];
                    // Search secondary
                    for (let i = 1; i <= 100; i++) {
                        const image = ret['Secondary_Image_' + i + '_URL'];
                        if (!image) break;
                        ret.Image_URLs.push(image);
                    }
                    ret.Image_URLs_small = ret.Image_URLs;

                    // Tableau (string si vide)
                    if (!ret.Lens_Width) ret.Lens_Width = [];
                    if (!ret.Rx) ret.Rx = [];
                    ret.customizable = CUSTOMIZABLE_FIELDS.some(field => ret[field].length > 1);


                    return ret;
                }).filter(product => {
                    if (product.isActive !== 'true') return false;
                    if (scope && scope.brandIds.length > 0 && scope.brandIds.indexOf(product.Brand_ID) === -1) return false;
                    if (scope && scope.eyewearCollectionIds.length > 0 && scope.eyewearCollectionIds.indexOf(product.Eyewear_Collection_ID) === -1) return false;
                    return true;
                });

                // We regroup
                const groups = new Map();
                for (let product of ProductService.allProducts) {
                    const groupKey = product.Brand_Brand_Name + '|' + product.Style_Code;
                    const groupMembers = groups.get(groupKey) || [];
                    if (!groupMembers.length) groups.set(groupKey, groupMembers);
                    groupMembers.push(product);
                }
                ProductService.products = Array.from(groups.keys()).map(groupKey => {
                    const members = groups.get(groupKey).sort((p1, p2) => {
                        const priceCompare = (p1.Retail_Price - p2.Retail_Price) || (p1.Dealer_Price - p2.Dealer_Price);
                        if (priceCompare) return priceCompare;
                        return p1.ID.localeCompare(p2.ID);
                    });
                    const groupPrimary = Object.assign({}, members[0]); // Clone
                    groupPrimary.groupMembers = members;
                    members.forEach(p => p.group = groupPrimary.ID); // Just the id instead of bidirectional link to avoir circular JSON ref
                    return groupPrimary;
                });
            })();
        }
        await ProductService.loading;

        // Apply filters
        let ret = ProductService.products.slice();
        if (filters && priceFilters) {
            ret = ret.filter(group => {
                const filteredMembers = group.groupMembers.filter(p => {
                    for (let filter in filters) {
                        const productValue = p[filter + '_ID'];
                        const filterValues = filters[filter];
                        if (!productValue) return false;
                        const split = productValue.split(',');
                        let found = false;
                        for (let val of split) {
                            if (filterValues.indexOf(val) > -1) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) return false;
                    }
                    for (let priceType of Object.keys(priceFilters)) {
                        const productPrice = Number(p[priceType]);
                        const bounds = priceFilters[priceType];
                        if (productPrice < bounds[0] || productPrice > bounds[1]) return false;
                    }
                    return true;
                });
                return (filteredMembers.length > 0); // Display group if at least one product works
            });
        }

        // Apply search/sort
        ret = ProductService.searchAndSort(ret, searchTerm, sort, sortReverse);

        // Pagination
        return ret.slice(offset, limit + offset);
    }

    static async getProducts(globalState, globalActions, offset = 0, limit = 16) {
        globalActions.fetchingData();

        let initialProduct = globalState.initialProduct;
        if (initialProduct) offset--;
        if (offset < 0) { // Cas du retour sur la home depuis la product view
            offset = 0;
            initialProduct = null;
        }

        const { activeFilters, priceFilters, activeSort, activeSortReverse, activeSearch } = globalState;
        /*
        let URL = `${process.env.REACT_APP_SERVER_BASE_URL}/products?limit=${limit}&offset=${offset}`;        
        if (typeof activeFilters !== 'undefined') {
            const filters = encodeFilters(activeFilters);
            URL = `${process.env.REACT_APP_SERVER_BASE_URL}/products?limit=${limit}&offset=${offset}&${filters}`;
        }
        const response = await fetch(URL);
        let data = await response.json();
        */
        let data = await ProductService.loadProducts(offset, limit, activeSort, activeSortReverse, activeFilters, priceFilters, activeSearch);
        const settings = await ApiService.getAPISettings();
        globalActions.settingsLoaded(settings);

        if (data.length < limit) globalActions.setAllDataLoaded();
        if (offset) data = globalState.productsData.slice(0, offset).concat(data);
        else if (initialProduct) data = [initialProduct].concat(data.filter(p => p.ID !== initialProduct.ID));

        globalActions.receiveData(data);
        return data;
    }

    static getProduct(productID) { // The product needs to be loaded/filtered
        return ProductService.allProducts.filter(p => p.ID === productID)[0];
    }
    static getGroup(productID) { // The product needs to be loaded/filtered
        return ProductService.products.filter(p => p.ID === productID)[0];
    }

    static searchAndSort(products, searchTerm, sort, reverse) {
        const ret = search(products, searchTerm);
        return sort ? ret.sort(sortFunction(sort, reverse)) : ret;
    }
}