import React from 'react'
import { Link } from 'react-router-dom'

const User = ({ count, active }) => (
  <Link to="/" className="l-header__link position-relative">
    <i alt="user-icon" className={`fas fa-home navbar-icon ${active ? 'icon-active' : ''}`} />
    {count > 0 ?
      <span className="sign position-absolute font-weight-bold h-bg-red-cl h-text-white-cl rounded-circle">{count}</span>
      : null
    }
  </Link>
)

export default User;