import React, { Component } from "react";
import RetailerInfo from "./RetailerInfo";

class OrderCartModal extends Component {
  state = {
    showRetailerInfoModal: false,
  };

  handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showRetailerInfoModal: true });
  };

  /* show calculate value panel */
  toggleCalculate = (e) => {
    e.stopPropagation();
    if (e.target.classList.contains("order-cart__calculate-value")) {
      window.$("body").addClass("order-cart--opened"); // Closed by App click
    }
  }

  render() {
    const {
      cart,
      STORE_BASE_CURRENCY,
      handleSubmitCart,
      submittingCart,
      orderConfirmed,
    } = this.props;
    const productCount = cart.cartProducts.reduce((a, b) => a + b.quantity, 0);
    return (
      <>
        {this.state.showRetailerInfoModal ? (
          <RetailerInfo
            orderConfirmed={orderConfirmed}
            showModal={this.state.showRetailerInfoModal}
            handleClose={() => this.setState({ showRetailerInfoModal: false })}
            submittingCart={submittingCart}
            handleSubmitCart={handleSubmitCart}
          />
        ) : (
            undefined
          )}
        <div className="order-cart__calculate h-bg-white-cl jsToggleCalculate" onClick={this.toggleCalculate}>
          <div className="order-cart__calculate-value text-center h-orange-cl h-pointer">
            Calculate Order Value
          </div>

          <div className="order-cart__calculate-hidden">
            { /*
            <div className="order-cart__calculate-header d-flex justify-content-between align-items-center h-orange-cl">
              <div className="d-flex align-items-start">
                <div>
                  <p>FourPlus General Trading LLC</p>
                </div>
              </div>
              <Link to="#">
                <img src="dist/img/12.png" alt="logo" />
              </Link>
            </div>
            */ }

            <div className="order-cart__calculate-content">
              <div className="order-cart__calculate-point mb-3">
                <span className="order-cart__calculate-name">
                  No. of Models
                </span>
                <span className="order-cart__calculate-size">
                  {cart.cartProducts.length}
                </span>
              </div>

              <div className="order-cart__calculate-point">
                <span className="order-cart__calculate-name">
                  No. of Product
                </span>
                <span className="order-cart__calculate-size">{productCount}</span>
              </div>

              <div className="order-cart__calculate-amount">
                <div className="order-cart__calculate-point mb-2">
                  <span className="order-cart__calculate-name">
                    Total Amount
                  </span>
                  <span className="order-cart__calculate-price">
                    {STORE_BASE_CURRENCY} {cart.total}
                  </span>
                </div>
                <div className="order-cart__calculate-point mb-2">
                  <span className="order-cart__calculate-name">
                    Shipping Costs (As Applicable)
                  </span>
                  <span className="order-cart__calculate-price">-</span>
                </div>
                <div className="order-cart__calculate-point mb-2">
                  <span className="order-cart__calculate-name">Tax (As Applicable)</span>
                  <span className="order-cart__calculate-price">-</span>
                </div>
              </div>

              <div className="order-cart__calculate-summary">
                <div className="order-cart__calculate-point">
                  <span className="order-cart__calculate-name">
                    Total Payable
                  </span>
                  <span className="order-cart__calculate-price">
                    {STORE_BASE_CURRENCY} {cart.total}
                  </span>
                </div>
              </div>

              <div className="order-cart__calculate-confirm d-flex justify-content-center align-items-center flex-column">
                <div className="text-center">
                  <button
                    className="btn-confirm h-bg-orange-cl h-text-white-cl text-uppercase"
                    onClick={this.handleClick}
                  >
                    Confirm Order
                  </button>
                </div>

                <div
                  className="text-center jsOrderPart2"
                  style={{ display: "none" }}
                >
                  <button className="btn-confirm h-bg-orange-cl h-text-white-cl text-uppercase">
                    PROCEED TO ORDER FORM
                  </button>
                  <p className="order-cart__calculate-text mt-3">
                    Your Order has been sent for Vendor Cofirmation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default OrderCartModal;
