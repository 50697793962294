import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { actions as homeActions } from "./Home/Home.duck";
import { actions as productPanelActions } from "./ProductPanel/ProductPanel.duck";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ScopeService } from "../services/Scope.Service";


function RouterListener(props) {
    const { pathname } = useLocation();

    ScopeService.initializeScope(props.actions); // Initialize scope on first access

    useEffect(() => {
        props.actions.toggleProductPanel(); // Clear selected product
        //props.actions.receiveData([]); // Reset product list
        props.actions.setInitialProduct(null);
        props.actions.setPathName(pathname);
        props.actions.toggleSearch('');
    }, [pathname, props.actions]);

    return null;
}

const mapStateToProps = (state, ownProps) => ({
    data: { ...state.home, ...state.productPanel },
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ ...homeActions, ...productPanelActions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterListener);