import React, { Component } from 'react'
import DiscountBadge from './DiscountBadge'

class DiscountBadgeFooter extends Component {
  render() {
    const { data, isInCart } = this.props;
    return (

      <div className="discount-panel">
        {
          data.discount > 0 ?
            <div className="discount-badge discount-badge--up">
              <DiscountBadge discount={data.discount} />
            </div> : null
        }
        {isInCart ? <div className="add-line h-bg-orange-cl"></div> : null}
      </div>
    )
  }
}

export default DiscountBadgeFooter;