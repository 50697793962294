import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import "./App.scss";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import OrderCart from "./components/OrderCart";
import Header from "./components/Header";
import Footer from "./components/Footer";
import WishList from "./components/WishList";
import ProductView from "./components/ProductView/Product";
import RouterListener from "./components/RouterListener";
import { actions as productPanelActions } from "./components/ProductPanel/ProductPanel.duck";

import configureStore, { saveState } from "./store";

const store = configureStore();
store.subscribe(() => {
  saveState(store.getState());
});

class App extends Component {
  handleGlobalClick = (e) => {
    store.dispatch(productPanelActions.toggleProductPanel(undefined));
    var target = e.target;
    window.$("body").removeClass(
      "filter-opened order-cart--opened"
    );
    if (
      !(
        window.$(target).data().toggle === "popover" ||
        window.$(target)
          .parent()
          .data().toggle === "popover"
      ) &&
      !window.$(target)
        .parents()
        .is(".popover.fade")
    ) {
      window.$('[data-toggle="popover"]').popover("hide");
    }
  };

  render() {
    return (
      <Provider store={store} >
        <Router>
          <div className="App" onClick={this.handleGlobalClick}>
            <RouterListener />
            <Navbar />
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/productview/:productId" component={ProductView} />
              <Route exact path="/ordercart" component={OrderCart} />
              <Route exact path="/wishlist" component={WishList} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </Provider>
    );
  }
};

export default App;
