import autodux from "autodux";

export const { actions, initial, reducer } = autodux({
  slice: "home",
  initial: {
    isFetching: false,
    allDataLoaded: false,
    productsData: [],
    favouritesList: [],
    wishList: [],
    wishListData: [],
    selectedBrands: [],
    activeFilters: {},
    priceFilters: {},
    activeSort: 'Brand_Brand_Name',
    activeSortReverse: false,
    activeSearch: '',
    scope: {}, // Allow to know where we are from (brand-id, optician-id, etc...)
    infoOpened: null, // Display info in ProductView
    displaySideBar: false, // For the product view
    initialProduct: null, // For the product view
    settings: null,
    pathname: null,
  },
  actions: {
    toggleFavouritesList: (state, item) => {
      const set = new Set(state.favouritesList);
      set.has(item) ? set.delete(item) : set.add(item);
      return {
        ...state,
        favouritesList: [...set]
      };
    },
    toggleWishList: (state, item) => {
      const set = new Set(state.wishList);
      let wishListItems = [...state.wishListData];
      if (set.has(item.ID)) {
        set.delete(item.ID);
        wishListItems = wishListItems.filter(itm => itm.ID !== item.ID);
      } else {
        set.add(item.ID);
        wishListItems.push(item);
      }
      return {
        ...state,
        wishList: [...set],
        wishListData: wishListItems
      };
    },
    fetchingData: state => ({
      ...state,
      isFetching: true,
      allDataLoaded: false
    }),
    receiveData: (state, productsData) => ({
      ...state,
      isFetching: false,
      productsData,
    }),
    setAllDataLoaded: state => ({
      ...state,
      allDataLoaded: true
    }),
    setFilteredData: (state, filteredData) => ({
      ...state,
      productsData: filteredData
    }),
    setInitialProduct: (state, initialProduct) => ({
      ...state,
      initialProduct
    }),
    settingsLoaded: (state, settings) => ({
      ...state,
      settings
    }),
    toggleDisplaySideBar: (state) => ({
      ...state,
      displaySideBar: !state.displaySideBar
    }),
    toggleInfoOpened: (state) => ({
      ...state,
      infoOpened: !state.infoOpened
    }),
    initActiveFilters: (state, activeFilters) => ({
      ...state,
      activeFilters
    }),
    toggleActiveFilters: (state, filterData) => {
      const { activeFilters } = state;
      const { name, id: filterId } = filterData;
      let newActiveFiltersState = {};
      const filters = Object.keys(activeFilters);
      if (filters.some(id => id === name)) {
        const filter = activeFilters[name];
        const set = new Set(filter);
        set.has(filterId) ? set.delete(filterId) : set.add(filterId);
        newActiveFiltersState = { ...activeFilters };
        delete newActiveFiltersState[name];
        newActiveFiltersState = {
          ...newActiveFiltersState,
          ...(set.size > 0 ? { [name]: [...set] } : undefined)
        };
      } else {
        newActiveFiltersState = {
          ...activeFilters,
          [name]: [filterId]
        };
      }
      return {
        ...state,
        activeFilters: newActiveFiltersState,
        initialProduct: null,
      };
    },
    setPriceFilter: (state, filterData) => {
      const { priceFilters } = state;
      const newPriceFiltersState = {
        ...priceFilters,
      };
      if (filterData.value) newPriceFiltersState[filterData.name] = filterData.value;
      else delete newPriceFiltersState[filterData.name];
      return {
        ...state,
        priceFilters: newPriceFiltersState,
        initialProduct: null,
      };
    },
    clearActiveFilters: (state) => ({
      ...state,
      activeFilters: {}
    }),
    toggleSort: (state, activeSort) => {
      let activeSortReverse = false;
      if (state.activeSort === activeSort && !state.activeSortReverse) activeSortReverse = true;
      return ({
        ...state,
        activeSort, activeSortReverse
      });
    },
    toggleSearch: (state, activeSearch) => ({
      ...state,
      activeSearch
    }),
    initializeScope: (state, scope) => ({
      ...state,
      scope
    }),
    setPathName: (state, pathname) => ({
      ...state,
      pathname
    }),
  }
});
