import autodux from 'autodux'

export const { actions, initial, reducer } = autodux({
  slice: 'wishlist',
  initial: {
  },
  actions: {
    performLogin: (state, { email, password }) => ({
      email,
      password,
      loading: true
    }),
  }
})