import React, { Component } from "react";

class PriceSlider extends Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    const $ = window.$;
    this.slider = $(this.sliderRef.current).slider({
      values: [0, this.props.maxAmount],
      min: 0,
      max: this.props.maxAmount,
      slide: (event, ui) => {
        if (ui.values[0] === 0 && ui.values[1] === this.props.maxAmount) this.props.onAmountChange(null);
        else this.props.onAmountChange(ui.values);
      }
    });
  }

  render() {
    const {
      slider_title,
      STORE_BASE_CURRENCY,
      values
    } = this.props;
    return (
      <div className="col-md-6 mb-4">
        <div className="filter__slider text-center">
          <div className="mb-3 h-orange-cl h-text-md">{slider_title}</div>
          <div ref={this.sliderRef}></div>
          <div>
            Price range:{" "}
            <span>
              {STORE_BASE_CURRENCY} {values[0]} - {STORE_BASE_CURRENCY} {values[1]}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default PriceSlider;
