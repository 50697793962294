import React, { Component } from 'react'
import Likefavorite from '../../img/like-favorite.svg';
import Likepartial from '../../img/like-partial.svg';
import Likegroup from '../../img/like-group.svg';
import Like from '../../img/like.svg';

class AddFavorite extends Component {
  render() {
    const { className, width, height, handleClick, productId, favouriteClass } = this.props;

    let image = Like;
    if (favouriteClass === 'favorite') image = Likefavorite;
    if (favouriteClass === 'partial') image = Likepartial;
    if (favouriteClass === 'group') image = Likegroup;

    return (
      <button
        className={className}
        onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleClick(productId) }}
        style={{ padding: 0 }}
      >
        <img src={image} className="product-like" width={width} height={height} alt="" />
      </button>
    )
  }
}

export default AddFavorite;