import React, { Component } from 'react'

export default class DiscountBadge extends Component {
  render() {
    const { discount } = this.props;
    return (
      <div>
        <span className="discount-badge__interest">{discount}</span>
        <span>%</span>{'\n'}
        <span>OFF</span>
        <div className="discount-badge__triangle" />
      </div>
    )
  }
}