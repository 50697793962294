import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ApiService } from "../../services/Api.Service";

class TotalCountModal extends Component {
  state = {
    filters: undefined
  };

  async componentDidMount() {
    const filters = await ApiService.getAvailableFilters();
    this.setState({ filters });
  }

  countProducts(name, value) {
    return this.props.products.filter(p => p[name + '_ID'] === value).length;
  }

  render() {
    if (!this.props.products || !this.state.filters) return null;

    return (
      <div
        className="modal fade total-count show"
        id="exampleModalLong"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalScrollableTitle">
                Total count
                </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ul>
                <li>
                  <Link to="#">
                    <span className="modal-text">Total Products</span>:
                      {this.props.products.length}
                  </Link>
                </li>
                {this.state.filters
                  .map((filter) => (
                    <li key={filter.name}>
                      <span to="#" className="modal-text">
                        {filter.title}
                      </span>
                      &nbsp;
                      <span>
                        {filter.options.map(
                          (option) => {
                            const count = this.countProducts(filter.name, option.id);
                            return count > 0 ? `${option.name} (${this.countProducts(filter.name, option.id)}) ` : null;
                          }
                        )}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TotalCountModal;
