import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../Home/Home.duck";
import ProductPanel from "../ProductPanel";
import { actions as productPanelActions } from "../ProductPanel/ProductPanel.duck";
import ProductList from "../Home/ProductList";
import { ApiService } from "../../services/Api.Service";
import { ProductService } from "../../services/Product.Service";
import Filter from "../Filter/Filter";
import './product-view.scss';

class ProductView extends Component {

  state = {
    STORE_BASE_CURRENCY: undefined,
  };

  getProductData = async productId => {
    await ProductService.loadProducts(0, 1);
    return ProductService.getProduct(productId);
  };

  async componentDidMount() {
    const {
      match: {
        params: { productId }
      },
    } = this.props;

    const initialProduct = await this.getProductData(productId);
    const initialGroup = ProductService.getGroup(initialProduct.group);
    this.props.actions.setInitialProduct(initialGroup);

    const apiSettings = await ApiService.getAPISettings();
    const STORE_BASE_CURRENCY = apiSettings['Base_Currency.Symbol'];
    this.setState({ STORE_BASE_CURRENCY });

    this.props.actions.toggleProductPanel(initialProduct);

    let paramFilters = this.props.location.search.substring(1).split('&').filter(q => q.startsWith('filters='))[0];
    if (paramFilters) {
      try {
        paramFilters = decodeURIComponent(paramFilters.substring(8));
        const newFilters = {};
        paramFilters.split('&').forEach(filterString => {
          const equal = filterString.indexOf('=');
          const filterValue = decodeURIComponent(filterString.substring(equal + 1));
          newFilters[filterString.substring(0, equal)] = filterValue.split(',');
        });
        this.props.actions.initActiveFilters(newFilters);
      } catch (ex) { }
    }
    ProductService.getProducts(this.props.globalState, this.props.actions, 1);
  }

  handleScroll = e => {
    const scrollHeight = e.target.scrollHeight;
    const scrollPos = e.target.getBoundingClientRect().height + e.target.scrollTop;
    if (!this.props.globalState.isFetching && !this.props.globalState.allDataLoaded && ((scrollHeight - 500) >= scrollPos) / scrollHeight === 0) {
      const offset = this.props.globalState.productsData.length;
      ProductService.getProducts(this.props.globalState, this.props.actions, offset);
    }
  };

  handleLastProductDisplayed = () => {
    if (!this.props.globalState.isFetching && !this.props.globalState.allDataLoaded) {
      const offset = this.props.globalState.productsData.length;
      ProductService.getProducts(this.props.globalState, this.props.actions, offset);
    }
  }

  render() {
    const { globalState } = this.props;
    const { favouritesList, displaySideBar, productsData } = globalState;
    return (
      <div className={`product-view-home product-flex-wrap d-flex ${displaySideBar ? 'product-flex-wrap--in-panel' : ''}`}>
        <Filter />
        {productsData.length > 0 ? (
          <>
            <div className="product-home" onScroll={this.handleScroll}>
              <ProductList
                productsData={productsData}
                favouritesList={favouritesList}
                listContentClass="single-row-data"
                groupped={true}
              />
            </div>
            <ProductPanel
              productsData={productsData}
              favouritesList={favouritesList}
              lastProductDisplayed={this.handleLastProductDisplayed}
              groupped={true}
            />
          </>
        ) : null
        }
        <div className="backdrop" />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  globalState: { ...state.home, ...state.cart, ...state.productPanel }
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions, ...productPanelActions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
