import React, { Component } from "react";

class FilterSelected extends Component {
  render() {
    const { filterTitle, filterLabel } = this.props;

    return (
      <span className="filter__selected is-checkbox">
        <span className="text-uppercase">{filterTitle}:</span> {filterLabel}
        <i className="fas h-pointer ml-1 fa-times h-orange-cl" onClick={this.props.onRemoveFilter}></i>
      </span>
    );
  }
}
export default FilterSelected;
