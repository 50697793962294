import React from "react";

export default props => {
  const product = props.product;
  const {
    about,
    Brand_Brand_Name,
    Gender_Gender_Name,
    Frame_Shape,
    Frame_Type,
    Frame_Material,
    Frame_Colour,
    Lens_Technology,
    Spring_Hinges
  } = product;
  return (
    <div className="tab-content text-left h-text-white-cl" id="navTabContent">
      <div
        className="tab-pane fade show active"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        {about}
      </div>
      <div
        className="tab-pane fade"
        id="nav-profile"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <table
          className="table table-bordered table-striped"
          style={{ maxWidth: 500, background: "#fff" }}
        >
          <tbody>
            <tr>
              <th className="label1">Brand</th>
              <td>{Brand_Brand_Name}</td>
            </tr>
            <tr>
              <th className="label1">Gender</th>
              <td>{Gender_Gender_Name}</td>
            </tr>
            <tr>
              <th className="label1">Frame Shape</th>
              <td>{Frame_Shape}</td>
            </tr>
            <tr>
              <th className="label1">Frame Type</th>
              <td>{Frame_Type}</td>
            </tr>
            <tr>
              <th className="label1">Frame Material</th>
              <td>{Frame_Material}</td>
            </tr>

            <tr>
              <th className="label1">Frame Colour</th>
              <td>{Frame_Colour}</td>
            </tr>
            <tr>
              <th className="label1">Lens Technology</th>
              <td>{Lens_Technology}</td>
            </tr>
            <tr>
              <th className="label1">Flex Hinges</th>
              <td>{Spring_Hinges ? "True" : "False"}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="tab-pane fade"
        id="nav-contact"
        role="tabpanel"
        aria-labelledby="nav-contact-tab"
      >
        <div className="row" style={{ backgroundColor: '#fff' }}>
          <figure
            className="col-lg-6 col-md-6 col-sm-6 col-xs-12"
            style={{ textAlign: "center" }}
          >
            <svg
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              width="329.045px"
              height="132.539px"
              viewBox="0 0 329.045 132.539"
              enableBackground="new 0 0 329.045 132.539"
            >
              <text
                transform="matrix(1 0 0 1 161.7708 106.2793)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="8"
              >
                Temple Length
              </text>
              <path
                fill="#DE7C3C"
                d="M73.724,19.95c0,0,94.699,7.173,135,12c43.021,5.153,47.836,7.607,65,18c21,12.715,40,17.571,40,31
    c0,13.43-8.285,5-15,0c-33.826-25.19-47.363-38-74-38c-12.856,0-52.57-1.285-80-3c-27.43-1.714-69-6-69-6L73.724,19.95z"
              ></path>
              <g>
                <g>
                  <g>
                    <line
                      fill="none"
                      stroke="#231F20"
                      strokeWidth="0.7"
                      strokeMiterlimit="10"
                      x1="152.521"
                      y1="112.951"
                      x2="64.08"
                      y2="112.951"
                    ></line>
                    <g>
                      <path
                        fill="#231F20"
                        d="M59.855,112.951c1.988-0.738,4.454-1.996,5.982-3.33l-1.204,3.33l1.204,3.328
                    C64.309,114.945,61.844,113.688,59.855,112.951z"
                      ></path>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <line
                      fill="none"
                      stroke="#231F20"
                      strokeWidth="0.7"
                      strokeMiterlimit="10"
                      x1="210.927"
                      y1="112.951"
                      x2="299.367"
                      y2="112.951"
                    ></line>
                    <g>
                      <path
                        fill="#231F20"
                        d="M303.593,112.951c-1.988,0.736-4.453,1.996-5.982,3.328l1.203-3.328l-1.203-3.328
                    C299.14,110.955,301.605,112.213,303.593,112.951z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <line
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.7"
                    strokeMiterlimit="10"
                    x1="41.974"
                    y1="82.881"
                    x2="41.974"
                    y2="28.018"
                  ></line>
                  <g>
                    <path
                      fill="#231F20"
                      d="M41.974,87.105c0.738-1.988,1.996-4.453,3.329-5.982l-3.329,1.203l-3.329-1.203
                C39.978,82.652,41.237,85.117,41.974,87.105z"
                    ></path>
                  </g>
                  <g>
                    <path
                      fill="#231F20"
                      d="M41.974,23.793c0.738,1.988,1.996,4.454,3.329,5.983l-3.329-1.205l-3.329,1.205
                C39.978,28.248,41.237,25.782,41.974,23.793z"
                    ></path>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <path
                    fill="none"
                    stroke="#585857"
                    strokeMiterlimit="10"
                    d="M53.964,49.857c-0.218-2.454-0.37-4.801-0.473-7.016
            C53.58,45.225,53.748,47.586,53.964,49.857z M273.724,49.95c-17.164-10.393-21.979-12.847-65-18c-40.301-4.827-135-12-135-12
            s-7.953-5-14-5c-4.375,0-8,1.125-8,5c0,1.774,2,4,2,4s1.764,0,4,0c12.625,0,11,13.084,11,39c0,18.25,1.375,27-2,27
            c-1.27,0-4.813-3.125-8-14c-0.724-2.468-1.353-4.938-1.901-7.392C56.51,77.441,59.678,93.95,67.724,93.95c9.25,0,8-14.25,8-22
            s0-38,0-38s41.57,4.286,69,6c27.429,1.715,67.143,3,80,3c26.637,0,40.174,12.81,74,38c6.715,5,15,13.43,15,0
            C313.724,67.521,294.724,62.665,273.724,49.95z M53.574,25.948c-0.002,0-3.851-0.372-3.851,7.002c0,7.259,3.609,8.889,3.724,8.938
            C53.148,34.538,53.393,28.803,53.574,25.948z M53.574,25.946c0,0,0,0.001,0,0.002l0,0V25.946z M53.574,25.946l0.149-1.996
            C53.724,23.95,53.655,24.662,53.574,25.946z M53.448,41.888c0.014,0.313,0.028,0.635,0.044,0.954
            c-0.013-0.315-0.022-0.632-0.032-0.949C53.458,41.893,53.45,41.889,53.448,41.888z M54.178,51.929
            c-0.07-0.689-0.154-1.396-0.215-2.072C54.031,50.558,54.103,51.248,54.178,51.929z M54.178,51.929
            c0.533,5.242,1.354,10.868,2.646,16.629c0.004-0.104,0.005-0.214,0.01-0.316C56.833,68.242,55.183,61.079,54.178,51.929z"
                  ></path>
                </g>
              </g>
              <text
                transform="matrix(1 0 0 1 14.9348 47.708)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="8"
              >
                Lens
              </text>
              <text
                transform="matrix(1 0 0 1 9.1575 55.9082)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="8"
              >
                Height
              </text>

              <text
                transform="matrix(1 0 0 1 24.4963 65.0684)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="7"
              ></text>

              <text
                transform="matrix(1 0 0 1 177.6633 115.3164)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="7"
              >
                {product.Temple_Length}
              </text>
            </svg>
          </figure>

          <figure
            className="col-lg-6col- md-6 col-sm-6 col-xs-12"
            style={{ textAlign: "center" }}
          >
            <svg
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              width="249.268px"
              height="123.686px"
              viewBox="0 0 249.268 123.686"
              enableBackground="new 0 0 249.268 123.686"
            >
              <g>
                <g>
                  <path
                    fill="none"
                    stroke="#585857"
                    strokeMiterlimit="10"
                    d="M186.634,25.48c-35.2,0-46,12-46,12c-5.856-2.57-13.469-3-17-3
			c-3.504,0-9.984,0.559-15,3c0,0-10.8-12-46-12s-51.399,2.801-55,9v7c0,0,4.625,2.5,7,15s6.857,48,49,48
			c31.325,0,41.247-16.477,46-33c3.703-12.873,4.491-24,15-24c8.713,0,11.245,10.941,15,24c4.754,16.523,14.677,33,46,33
			c42.144,0,46.625-35.5,49-48s7-15,7-15v-7C238.035,28.281,221.834,25.48,186.634,25.48z M62.634,99.479c-26,0-39-19.5-39-39
			s4.667-29,39-29c23,0,43,4.25,43,28S88.634,99.479,62.634,99.479z M186.634,99.479c-26,0-43-16.25-43-40s20-28,43-28
			c34.334,0,39,9.5,39,29S212.634,99.479,186.634,99.479z"
                  ></path>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <line
                      fill="none"
                      stroke="#231F20"
                      strokeWidth="0.7"
                      strokeMiterlimit="10"
                      x1="46.884"
                      y1="62.272"
                      x2="30.109"
                      y2="62.272"
                    ></line>
                    <g>
                      <path
                        fill="#231F20"
                        d="M25.884,62.272c1.988-0.737,4.454-1.996,5.983-3.329l-1.205,3.329l1.205,3.329
					C30.339,64.266,27.872,63.009,25.884,62.272z"
                      ></path>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <line
                      fill="none"
                      stroke="#231F20"
                      strokeWidth="0.7"
                      strokeMiterlimit="10"
                      x1="83.218"
                      y1="62.272"
                      x2="99.993"
                      y2="62.272"
                    ></line>
                    <g>
                      <path
                        fill="#231F20"
                        d="M104.218,62.272c-1.987,0.739-4.454,1.997-5.982,3.329l1.204-3.329l-1.204-3.329
					C99.763,60.276,102.23,61.535,104.218,62.272z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <line
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.7"
                    strokeMiterlimit="10"
                    x1="95.43"
                    y1="113.438"
                    x2="13.989"
                    y2="113.438"
                  ></line>
                  <g>
                    <path
                      fill="#231F20"
                      d="M9.764,113.438c1.988-0.735,4.454-1.996,5.983-3.328l-1.204,3.328l1.204,3.328
				C14.219,115.434,11.753,114.177,9.764,113.438z"
                    ></path>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <line
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.7"
                    strokeMiterlimit="10"
                    x1="153.838"
                    y1="113.438"
                    x2="235.279"
                    y2="113.438"
                  ></line>
                  <g>
                    <path
                      fill="#231F20"
                      d="M239.504,113.438c-1.988,0.738-4.454,1.996-5.982,3.33l1.203-3.33l-1.203-3.328
				C235.049,111.444,237.514,112.702,239.504,113.438z"
                    ></path>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <line
                    fill="none"
                    stroke="#231F20"
                    strokeWidth="0.7"
                    strokeMiterlimit="10"
                    x1="116.092"
                    y1="16.522"
                    x2="134.957"
                    y2="16.522"
                  ></line>
                  <g>
                    <path
                      fill="#231F20"
                      d="M111.868,16.522c1.987,0.738,4.453,1.996,5.982,3.329l-1.205-3.329l1.205-3.329
				C116.322,14.526,113.856,15.785,111.868,16.522z"
                    ></path>
                  </g>
                  <g>
                    <path
                      fill="#231F20"
                      d="M139.18,16.522c-1.987,0.738-4.454,1.996-5.982,3.329l1.204-3.329l-1.204-3.329
				C134.726,14.526,137.193,15.785,139.18,16.522z"
                    ></path>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <line
                      fill="none"
                      stroke="#231F20"
                      strokeWidth="0.7"
                      strokeMiterlimit="10"
                      x1="172.84"
                      y1="55.196"
                      x2="157.942"
                      y2="42.744"
                    ></line>
                    <g>
                      <path
                        fill="#231F20"
                        d="M154.701,40.035c1.998,0.709,4.697,1.323,6.726,1.281l-3.059,1.782l-1.212,3.326
					C156.839,44.422,155.754,41.875,154.701,40.035z"
                      ></path>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <line
                      fill="none"
                      stroke="#231F20"
                      strokeWidth="0.7"
                      strokeMiterlimit="10"
                      x1="193.398"
                      y1="72.38"
                      x2="208.296"
                      y2="84.831"
                    ></line>
                    <g>
                      <path
                        fill="#231F20"
                        d="M211.537,87.54c-1.998-0.709-4.698-1.324-6.727-1.283l3.06-1.781l1.211-3.326
					C209.399,83.153,210.484,85.7,211.537,87.54z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>

              <text
                transform="matrix(1 0 0 1 121.6311 27.2405)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="7"
              >
                {product.Bridge_Size}
              </text>
              <g>
                <text
                  transform="matrix(1 0 0 1 105.0691 10.7258)"
                  fill="#231F20"
                  fontFamily="'Arial'"
                  fontSize="8"
                >
                  Bridge Size
                </text>
              </g>
              <text
                transform="matrix(1 0 0 1 43.4167 55.467)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="8"
              >
                Lens Width
              </text>
              <g>
                <text
                  transform="matrix(1 0 0 1 104.2385 106.7092)"
                  fill="#231F20"
                  fontFamily="'Arial'"
                  fontSize="8"
                >
                  Total Width
                </text>
              </g>
              <g>
                <text
                  transform="matrix(1 0 0 1 177.1594 58.5256)"
                  fill="#231F20"
                  fontFamily="'Arial'"
                  fontSize="8"
                >
                  ED
                </text>
              </g>

              <text
                transform="matrix(1 0 0 1 59.5334 65.259)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="7"
              >
                {product.Lens_Width}
              </text>

              <text
                transform="matrix(1 0 0 1 120.574 115.8342)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="7"
              ></text>

              <text
                transform="matrix(1 0 0 1 178.8225 66.6545)"
                fill="#231F20"
                fontFamily="'Arial'"
                fontSize="7"
              ></text>
            </svg>
          </figure>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="nav-contact1"
        role="tabpanel"
        aria-labelledby="nav-contact-tab"
      >
        {product.ratingreview}
      </div>
    </div>
  );
};
