import React from 'react'
import { Link } from 'react-router-dom'

const Cart = ({ active, count }) => (
  <Link to="/ordercart" className="l-header__link l-header__link--sign position-relative mr-3">
    <i alt="cart-icon" className={`fas fa-shopping-cart navbar-icon ${active ? 'icon-active' : ''}`} />
    {count > 0 ?
      <span className="sign position-absolute font-weight-bold h-bg-red-cl h-text-white-cl rounded-circle">{count}</span>
      : null
    }
  </Link>
)

export default Cart;