import { combineReducers } from 'redux'
import * as homeDuck from '../components/Home/Home.duck'
import * as navbarDuck from '../components/Navbar/Navbar.duck'
import * as wishlistDuck from '../components/WishList/Wishlist.duck'
import * as OrderCartDuck from '../components/OrderCart/OrderCart.duck'
import * as ProductPanelDuck from '../components/ProductPanel/ProductPanel.duck'

export default combineReducers({
  home: homeDuck.reducer,
  navbar: navbarDuck.reducer,
  wishlist: wishlistDuck.reducer,
  cart: OrderCartDuck.reducer,
  productPanel: ProductPanelDuck.reducer,
})