export const encodeFilters = obj =>
    Object.keys(obj)
        .map(k => `${k}=${encodeURIComponent(obj[k])}`)
        .join("&");

export const vendorLoadedPromise = new Promise(resolve => {
    const check = () => {
        if (window.$ && window.$().slick && window.$().easyZoom) resolve();
        else setTimeout(check, 100);
    }
    check();
});